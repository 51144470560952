@media screen {
    #document {
        position: fixed;
        top: 0;
        left: 0;
        background-color: #EDEDED;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-touch-callout: default;
        -webkit-touch-callout: none;
    }

    #preload {
        color: #777;
        font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
        text-align: center;
    }

    #preload-header {
        border-bottom: 1px solid #777;
        margin: 5em auto 0 auto;
        padding: 0 0 .3em 0;
        text-transform: uppercase;
        width: 15em;
    }

    #header {
        background-color: #333;
        border-bottom: 1px solid #AAA;
        color: #FFF;
        font-family: Helvetica, Arial, Verdana, sans-serif;
        padding: 10px 0;
        z-index: 1000;
        position: absolute;
        top: 0;
        width: 100%;
    }

    #header #name {
        color: #EEE;
        font-size: 16px;
        font-weight: normal;
        line-height: 1;
        margin: 0;
        border: none;
        padding: 0;
        text-align: center;
        max-width: calc(100% - 166px);
        display: inline-block;
    }

    #header #toolbox {
        position: absolute;
        top: 7px;
        right: 20px;
    }

    #header #toolbox a {
        text-decoration: none;
        color: #EEE;
        font-size: 14px;
    }

    #header #toolbox a span {
        margin-top: -12px;
        display: inline-block;
    }

    #header .error {
        color: #fff7a7;
        text-align: center;
        margin: 5px 0 0 0;
        font-size: .9em;
        font-weight: bold;
    }
    #header > p {
        margin: 0 20px 0 10px;
        cursor: pointer;
        vertical-align: top;
        display: inline-block;
    }
    #header p {
        cursor: pointer;
    }

    #content #content-frame {
        transform-origin: top left;
    }

    #content {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        position: absolute;
    }
    #preload-copyright {
        position: absolute;
        bottom: 10px;
        width: 100%;
        text-align: center;
    }
}
@media print {
    @page {
        size: auto;
        margin: 0;
    }

    html {
        margin: 0;
    }

    body {
        margin: 10mm 15mm;
        -webkit-user-select: initial;
        -moz-user-select: initial;
        -ms-user-select: initial;
        user-select: initial;
        -webkit-touch-callout: initial;
        background: #fff;
    }
    div, p, a {
        display: none;
    }
    header {
        display: none;
    }
    #root, #root > div , #root > div > div.page, #root > div > div.page > div {
        display: block;
    }
    #root > div > div.page > div > div#document {
        display: block;
    }
    #document, #document div, #document p, #document a {
        display: block;
    }
    .pf {
        margin-top: -22px !important;
        page-break-after: always;
    }
    #header {
        display: none !important;
    }
}
