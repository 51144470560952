#hero {
    text-align: center;
    padding-top: 20px;
    color: #fff;
    width: 100%;
    height: 224px;
    background: #027bb8;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
#hero p {
    font-size: 12px;
}
#hero p.title {
    font-size: 30px;
    font-weight: bold;
    font-family: "Nunito", Helvetica, sans-serif;
    text-shadow: 1px 1px 3px #000;
    z-index: 2;
    position: relative;
}
#hero p.title.blue-heading {
    text-shadow: 1px 1px 3px #fff;
}
#hero .blue-heading {
    color: #0e5384;
}
#hero .small-logo {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    border: 4px solid #0e5384;
}
#hero .small-logo img {
    height: 42px;
    width: 47.5px;
}
#hero .edit {
    position: absolute;
    right: 13px;
    bottom: 10px;
    cursor: pointer;
    font-size: 20px;
}
#hero .profile-pic {
    position: relative;
    margin: 0 auto;
    width: 150px;
    z-index: 2;
}
#avatar-overlay {
    position: fixed;
    z-index: 99999;
    background: rgba(50,50,50,0.5);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
#avatar-overlay .popup {
    width: 550px;
    margin: 7% auto 0;
    background: #fff;
    position: relative;
    border-radius: 6px;
    border: solid 1px #707070;
    padding: 15px 20px;
    text-align: center;
    max-width: 100%;
}
#avatar-overlay .close {
    position: absolute;
    right: 8px;
    top: 4px;
    cursor: pointer;
    color: #0e5384;
    font-size: 24px;
}
#avatar-overlay .popup h2 {
    color: #0e5384;
    font-size: 32px;
    margin-bottom: 6px;
}
#avatar-overlay .avatar-select {
    height: 300px;
    overflow-y: auto;
    padding: 5px 20px;
    background: #f1f1f1;
}
#avatar-overlay .avatar {
    width: 23%;
    margin: 5px 1% 5px;
    float: left;
    text-align: center;
    position: relative;
    list-style: none;
    cursor: pointer;
}
#avatar-overlay .avatar.clicked img {
    border: 4px solid #0e5384;
}
#avatar-overlay .avatar img {
    width: 85px;
    height: auto;
    max-width: 100%;
    border-radius: 50%;
    pointer-events: none;
}
#avatar-overlay .avatar.active img {
    border: 4px solid #0e5384;
}
#avatar-overlay button {
    font-weight: 700;
    padding: 10px;
    width: 46%;
    border-radius: 3px;
    margin: 15px 2% 5px;
    cursor: pointer;
    font-size: 24px;
    border: 1px solid #76b32e;
    background-color: #76b32e;
    color: #fff;
}
#avatar-overlay .buttons {
    text-align: center;
}
#avatar-overlay .avatar-select::-webkit-scrollbar {
    width: 10px;
}
#avatar-overlay .avatar-select::-webkit-scrollbar-track {
    background: #f1f1f1;
}
#avatar-overlay .avatar-select::-webkit-scrollbar-thumb {
    background: #888;
}
#avatar-overlay .avatar-select::-webkit-scrollbar-thumb:hover {
    background: #555;
}
#avatar-overlay #theme-popup .avatar {
    padding-bottom: 17%;
    border-radius: 10px;
}
#avatar-overlay #theme-popup .avatar.active {
    border: 4px solid #0e5384;
}
#avatar-overlay .switch-popup {
    text-align: center;
    color: #0b76b4;
    cursor: pointer;
    display: inline-block;
    width: 48%;
    margin: 0 1% 10px;
    padding: 10px 0;
    font-size: 18px;
    border-radius: 8px;
}
#avatar-overlay .switch-popup.active {
    background-color: #0b76b4;
    color: #fff;

}
.dashboard-changer-container {
    position: relative;
    width: 300px;
    margin: 0 auto;
}
.dashboard-changer-container span {
    cursor: pointer;
}
#changeDashboard {
    display: none;
    background: #0e5384;
    position: absolute;
    padding: 10px 20px;
    z-index: 9;
    list-style: none;
    width: 100%;
}
#changeDashboard li {
    margin: 2px 0;
    cursor: pointer;
}
.top-babies {
    position: absolute;
    top: -24px;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}
.top-babies img {
    position: absolute;
    left: 50%;
    bottom: 26px;
}
.top-babies img:first-child  {
    margin-left: -360px;
}
.top-babies img:last-child {
    margin-left: 235px;
}
@media (max-width: 1080px) {
    .top-babies img:first-child  {
        margin-left: -280px;
    }
    .top-babies img:last-child {
        left: auto;
        right: 0;
    }
}
@media (max-width: 900px) {
    .top-babies img:first-child  {
        margin-left: -240px;
    }
    .top-babies img:last-child {
        margin-left: 45px;
    }
}
@media (max-width: 480px) {
    #avatar-overlay #theme-popup .avatar {
        width: 31%;
        padding-bottom: 23%;
    }
    .top-babies {
        display: none;
    }
}