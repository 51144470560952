.printables {
    width: 100%;
}
.printables h3 {
    margin: 10px 0;
    padding: 0;
    border-bottom: none;
}
.printables span.table-heading {
    color: #383b41;
    border-bottom: 2px solid #383b41;
    width: 15.5%;
    padding: 0 2.67% 5px;
}
h4 {
    font-size: 16px;
    margin: 5px 1%;
}
.printables table {
    border: none;
    width: 100%;
    margin: 10px 0 20px;
    text-align: center;
    border-spacing: 0;
    font-size: 16px;
}
.printables th {
    background-color: #fff;
    color: #000;
    border-bottom: 1px solid #bfbfbf;
    padding: 10px 0;
    font-size: 16px;
    font-weight: normal;
    vertical-align: bottom;
    position: relative;
}
.printables th strong {
    font-size: 18px;
}

.printables th:first-child {
    text-align: left;
    position: relative;
}
.printables tr td {
    padding: 10px 0;
    border: none;
    color: #383b41;
}
.printables tr td:first-child {
    width: 48%;
    text-align: left;
    padding: 10px;
    color: #0e5384;
}
.printables tr td:nth-child(2) {
    padding: 5px 0;
}
.printables tr td:nth-child(2), .printables tr td:nth-child(3),
.printables tr td:nth-child(4) {
    width: 16%;
    color: #000;
}
.printables svg {
    vertical-align: sub;
    margin-right: 5px;
}
.printables tr:nth-child(odd) {
    background: #fff;
}
.printables tr:nth-child(even) {
    background: #eaeaea;
}
.printables table.closed thead tr:nth-child(2),
.printables table.closed tbody {
    display: none;
}
.printables table thead .category-name {
    cursor: pointer;
}
.printables table thead .category-name span strong {
    color: #0e5384;
}
.printables table .caret {
    transition: all 500ms ease;
}
.printables .printable-test.open .caret.test-name {
    transform: rotate(180deg);
}
.printables table.open .caret {
    transform: rotate(180deg);
}
.printables .closed .printables {
    display: none;
}
.switch {
    position: relative;
    display: block;
    width: 95px;
    height: 25px;
    margin: 0 auto;
}
.switch input {
    display: none;
}
.slider.round {
    border-radius: 34px;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0e5384;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider.round:before {
    border-radius: 50%;
}
.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    right: 5px;
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider .yes-no {
    color: #fff;
    position: absolute;
    top: 2px;
    left: 12px;
    font-size: 16px;
    font-weight: bold;
}
.slider .no {
    display: inline;
}
.slider .yes {
    display: none;
}
.slider.clicked {
    background-color: #17b7a9;
}
.slider.clicked .yes-no {
    right: 12px;
    left: unset;
}
.slider.clicked .no {
    display: none;
}
.slider.clicked .yes {
    display: inline;
}
.slider.clicked:before {
    left: 5px;
}
.printables td.disabled .switch {
    pointer-events: none;
}
.printables td.disabled .slider, .printables td.disabled .slider.clicked {
    background-color: #a9a9a9;
    pointer-events: none;
}
.printables .caret {
    position: absolute;
    right: 0;
    font-size: 32px;
    color: #0e5384;
}
.printables th .caret {
    top: 8px;
}
.printables .printable-test h3, #test-dashboard .printables .printable-test h3 {
    font-size: 24px;
    line-height: 1.33;
    text-align: left;
    color: #383b41;
    border-bottom: 2px solid #383b41;
    padding-bottom: 5px;
    margin: 30px 0 10px;
    position: relative;
    padding-right: 25px;
    cursor: pointer;
}

@media (max-width: 960px) {
    .printables th {
        font-size: 16px;
        padding: 10px 3px 5px;
    }
    .switch {
        width: 75px;
    }
    .slider .yes-no {
        left: 10px;
    }
    .slider.clicked:before {
        left: 5px;
    }
}
@media (max-width: 520px) {
    .printables table thead .category-name span {
        margin-left: 30px;
    }
    .printables table .caret {
        left: 0;
        right: auto;
    }
}
@media (max-width: 450px) {
    .printables {
        overflow-x: auto;
    }
    .printables table {
        min-width: 400px;
    }
}
