#tutoring p, #tutoring h1, #tutoring h2, #tutoring h3, #tutoring h4, #tutoring h5 {
    color: #383B41;
}
#tutoring input[type=text], #tutoring textarea {
    display: block;
    padding: 7px 1% 6px 1%;
    font-family: "Nunito Sans", Helvetica, Arial, sans-serif;
    color: #333;
    width: 100%;
    border: 1px solid #dbe1e9;
    box-sizing: border-box;
}
#tutoring button {
    border: none;
}
#tutoring select {
    border: 1px solid #dbe1e9;
    height: 29px;
    background: #f1f1f2;
    width: 100%;
    color: #888;
    text-indent: 1%;
    margin-bottom: 15px;
    box-sizing: border-box;
}
#tutoring .loading {
    position: relative;
}
#tutoring .tm-loading {
    min-height: 200px;
}
#tutoring .tiny-tm-loading {
    max-width: 50px;
    min-width: 50px;
    width: 50px;
    max-height: 50px;
    min-height: 50px;
    height: 50px;
    background-size: contain;
    aspect-ratio: 1;
}
#tutoring .overview, #tutoring .sign-up, #tutoring .messages-container, #tutoring .calendar-container, #tutoring .tutor-select {
    display: none;
}
#tutoring .sign-up {
    text-align: center;
    margin-top: 20px;
}
#tutoring .sign-up p {
    font-weight: 700;
    font-size: 24px;
    color: #383B41;
}
#tutoring .sign-up-btn {
    background-color: #6523D2;
    padding: 14px 28px;
    width: fit-content;
    margin: 20px auto 40px;
}
#tutoring .btn, #tutoring .hw-btn, #tutoring .upload-hw, #tutoring #hw-form input[type=submit] {
    display: block;
    margin-top: 0;
    background-color: #0B76B4;
    border-radius: 5px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    padding: 13px 25px;
    cursor: pointer;
    width: fit-content;
    text-align: center;
    height: fit-content;
}
#tutoring .hw-btn, #tutoring .upload-hw, #tutoring #hw-form input[type=submit] {
    margin-top: 25px;
}
#tutoring .btn:hover, #tutoring .hw-btn:hover, #tutoring .upload-hw:hover {
    background-color: #085e8a;
}
#tutoring .overview {
    padding: 20px;
}
#tutoring .overview-columns {
    display: flex;
    flex-wrap: wrap;
}
#tutoring .overview-columns > div:first-child {
    padding-right: 40px;
    border-right: 1px solid #DDE6EE;
    flex-basis: 65%;
}
#tutoring .overview-columns > div:last-child {
    flex-basis: 35%;
    padding-left: 40px;
}

#tutoring h4 {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
}
#tutoring .student-dropdown {
    position: relative;
}
#tutoring .student-name {
    background: linear-gradient(90.02deg, rgba(151, 71, 255, 0.09) 0.02%, rgba(151, 71, 255, 0) 99.99%);
    cursor: pointer;
    padding: 5px 20px;
    margin-top: 5px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    min-height: 59px;
}
#tutoring .student-dropdown .student-option.selected {
    display: none;
}
#tutoring .student-dropdown .student-option .new-message {
    background: #F15A2D;
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 10px;
}
#tutoring .student-name:hover, #tutoring .student-name.active {
    background: rgba(101, 35, 210, 0.07);
}
#tutoring .student-name h1 {
    font-weight: 700;
    font-size: 36px;
    color: #383B41;
    text-align: left;
    margin-bottom: 0;
    margin-right: 20px;
}
#tutoring .student-name i {
    font-size: 12px;
}
#tutoring .other-students {
    position: absolute;
    z-index: 10;
    top: 59px;
    width: 100%;
    list-style-type: none;
    margin: 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    max-height: 295px;
    overflow: auto;
    display: none;
}
#tutoring .other-students li {
    width: 100%;
    background-color: #FFFFFF;
    color: #000000;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    padding: 11px 20px;
    border-bottom: 1px solid #EEEBF2;
    cursor: pointer;
    position: relative;
}
#tutoring .other-students li:hover {
    background-color: #FAF7FF;
}
#tutoring .other-students .search-li {
    padding: 10px 10px;
    cursor: auto;
}
#tutoring .other-students #student-search {
    margin-bottom: 0;
    background-color: #FAFAFA;
    border: 1px solid #E6E6E6;
    border-radius: 2px;
    padding-left: 20px;
    font-size: 16px;
    line-height: 22px;
    cursor: auto;
}
#tutoring .section-title {
    font-size: 16px;
    font-weight: 700;
}
#tutoring #messages {
    margin-bottom: 35px;
}
#tutoring .messages {
    margin-bottom: 10px;
}
#tutoring .messages-header {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 15px;
}
#tutoring .messages-header a {
    font-size: 14px;
    text-decoration: underline;
}
#tutoring .messages-header p {
    margin-bottom: 0;
}
#tutoring .no-messages {
    display: none;
    margin-bottom: 20px;
}
#tutoring .no-messages p {
    color: #9A9B9C;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}
#tutoring #messages .messages .message-container:nth-child(n + 3) {
    display: none;
}
#tutoring #messages .controls a.view-more {
    display: none;
    margin-bottom: 10px;
}
#tutoring .recent-message, #tutoring .message {
    border-left: 3px solid #6523D2;
    padding-left: 20px;
}
#tutoring .message-container  {
    margin: 20px 0;
    border-bottom: 1px solid #b7a9d0;
}
#tutoring .message-container .message:nth-child(n+2) {
    display: none;
}
#tutoring #messages .controls a {
    display: inline-block;
    margin-top: 10px;
}
#tutoring .self.message {
    border-left: 3px solid #b7a9d0;
}
#tutoring #messages {
    display: none;
    margin-top: 30px;
}
#tutoring #messages .tm-loading {
    width: 100px;
    height: 100px;
    min-height: 0;
    background-size: 100%;
    margin: 0 auto;
}
#tutoring .message-info {
    font-weight: 400;
    font-size: 14px;
    color: #9A9B9C;
    line-height: 160%;
    margin-bottom: 10px;
}
#tutoring .message-subject {
    margin-bottom: 5px;
    line-height: 160%;
    font-size: 18px;
}
#tutoring .message-body {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 18px;
    line-height: 160%;
    white-space: pre-wrap;
    max-width: 760px;
}
#tutoring .message-attachment {
    margin-bottom: 10px;
}
#tutoring .reply-box {
    display: none;
    width: 100%;
}
#tutoring #messages .reply-box > .tiny-tm-loading {
    display: none;
    margin-left: 0;
    position: relative;
}
#tutoring #messages > .controls {
    margin: -10px 0 60px;
}
#tutoring .controls {
    margin-top: -14px;
    font-size: 14px;
    margin-bottom: 10px;
}
#tutoring input[type=text], #tutoring textarea {
    background: #fcfcfc;
    border-color: #310f68;
    color: #333;
    font-size: 18px;
}
#tutoring .tutor-select input[type=text], #tutoring #add-edit-session input[type=text] {
    border-color: #dbe1e9;
    font-size: 14px;
}
#tutoring button[name='send_message'] {
    background: #310f68;
    color: #fff;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 1px 1px 3px #999;
    text-shadow: 1px 1px 0 #999;
    cursor: pointer;
    margin-bottom: 20px;
}
#tutoring .btn-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
}
#tutoring .purchase-btn {
    margin-top: 0;
    background-color: #F15A2D;
}
#tutoring .purchase-btn:hover {
    background-color: #ec4413;
}
#tutoring .active-tests .section-title {
    margin-bottom: 15px;
}
#tutoring .tests {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
#tutoring .test {
    background-color: #F6FCFF;
    border-radius: 3px;
    display: flex;
    padding: 17px 17px 20px;
}
#tutoring .test .test-tutor {
    position: relative;
}
#tutoring .test .test-tutor, #tutoring .test .test-tutor img {
    width: 50px;
    height: 50px;
    min-height: 50px;
    min-width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
}
#tutoring .test .test-tutor img {
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#tutoring .test .test-info {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    color: #000000;
}
#tutoring .test .test-info p, #tutoring .test .test-grade p {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 5px;
}
#tutoring .test .test-info .test-title, #tutoring .test .test-grade .grade {
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
}
#tutoring .test .test-info a {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}
#tutoring .test .subject-hw {
    text-decoration: underline;
    cursor: pointer;
}
#tutoring .test .test-grade {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: 15px;
}
#tutoring #hw-upload {
    display: none;
}
#tutoring .upload-hw, #tutoring #hw-form input[type=submit] {
    margin: 15px auto 0;
}
#tutoring #hw-form input[type=submit] {
    display: none;
    background-color: #0a8c3a;
}
#tutoring #hw-form .file-upload {
    margin: 0 auto;
    width: fit-content;
}
#tutoring #hw-form .file-upload:first-of-type {
    margin-top: 10px;
}
#tutoring #hw-form input[type=submit]:hover {
    background-color: #147035;
}
#tutoring #hw-form .hw-notes-entry {
    display: none;
}
#tutoring .calendar-date {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
#tutoring .arrow {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DDE6EE;
    cursor: pointer;
}
#tutoring .arrow:hover {
    border: 1px solid #383B41;
}
#tutoring .arrow:active {
    color: #FFFFFF;
    background-color: #383B41;
}
#tutoring .calendar-date .section-title {
    margin-left: 15px;
    margin-bottom: 0;
}
#tutoring #calendar-scope {
    margin-left: auto;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 160px;
    height: 30px;
    background-color: #FFFFFF;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    font-family: "Nunito Sans", Helvetica, Arial, sans-serif;
    border: 1px solid #E6E6E6;
    cursor: pointer;
}
#tutoring #session-list .tiny-tm-loading {
    position: relative;
    margin: 0 auto;
}
#tutoring .sessions {
    margin: 0;
}
#tutoring .sessions .session {
    padding: 10px;
    min-height: 55px;
    margin-bottom: 6px;
    background-color: rgba(101, 35, 210, 0.05);
    display: flex;
    flex-direction: row;
    align-items: center;
}
#tutoring .sessions .session.empty {
    background-color: transparent;
}
#tutoring .sessions .session-date, #tutoring .sessions .session-title {
    display: flex;
    flex-direction: column;
}
#tutoring .sessions .session-date {
    width: 68px;
    min-height: 35px;
    padding-right: 10px;
    border-right: 1px solid rgba(101, 35, 210, 0.06);
    margin-right: 5px;
}
#tutoring .sessions .session-info {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}
#tutoring .sessions .session-title {
    padding-left: 5px;
}
#tutoring .sessions .day, #tutoring .sessions .time {
    font-size: 12px;
    color: #383B41;
    margin: 0;
}
#tutoring .sessions .date, #tutoring .sessions .title {
    font-weight: 700;
    font-size: 14px;
    color: #383B41;
    margin: 0;
}
#tutoring .sessions .title {
    color: #6523D2;
}
#tutoring .sessions .join {
    display: flex;
    flex-direction: row;
    align-items: center;
}
#tutoring .sessions .time-till {
    font-weight: 700;
    font-size: 12px;
    color: #383B41;
    padding-left: 5px;
    display: flex;
    align-items: center;
}
#tutoring .sessions .time-till .fa-clock {
    color: #6523D2;
    padding-right: 5px;
}
#tutoring .sessions .time-till.edit-time-till {
    padding: 0 3px;
    border: 1px solid #6523D2;
    border-radius: 3px;
    background-color: transparent;
    cursor: pointer;
}
#tutoring .sessions .time-till .fa-pen {
    color: #6523D2;
    opacity: .3;
    background-color: transparent;
    margin: 2px 0;
}
#tutoring .sessions .time-till .fa-pen:hover {
    opacity: 1;
}
#tutoring .sessions .time-till.edit-time-till:active, #tutoring .sessions .time-till .fa-pen:active {
    color: #FFFFFF;
    background-color: #6523D2;
}
#tutoring .sessions .time-till p {
    margin: 0;
}
#tutoring .sessions .join-btn {
    padding: 4px 15px;
    font-weight: 700;
    font-size: 12px;
    color: #FFFFFF;
    background-color: #6523D2;
    border-radius: 3px;
    margin-left: 16px;
    opacity: .3;
    text-align: center;
}
#tutoring .sessions .join-btn.active {
    opacity: 1;
    cursor: pointer;
}
#tutoring .sessions .join-btn.active:hover {
    background: #8033FF;
}
#tutoring .sessions .join-btn.active:active {
    background: #4C1A9E;
}
#tutoring .tutor-selection {
    display: inline-flex;
    gap: 25px;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-top: 10px;
    margin-bottom: 40px;
}
#tutoring .tutor-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    opacity: .5;
    margin-bottom: 5px;
}
#tutoring .tutor-icon:hover {
    opacity: 1;
}
#tutoring .tutor-icon .img-container, #tutoring .tutor-icon .img-container img {
    min-height: 80px;
    min-width: 80px;
    height: 80px;
    width: 80px;
    border-radius: 50%;
}
#tutoring .tutor-icon .img-container {
    position: relative;
}
#tutoring .tutor-icon .img-container img {
    aspect-ratio: 1;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
#tutoring .tutor-icon .img-container .img-letter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
    height: fit-content;
    width: fit-content;
    color: #FFFFFF;
    font-weight: bold;
    font-family: "Nunito", Helvetica, Arial, sans-serif;
}
#tutoring .tutor-icon p {
    font-weight: 700;
    font-size: 12px;
    margin: 3px 0 0;
}
#tutoring .tutor-icon.selected {
    opacity: 1;
}
#tutoring .tutor-icon.selected .img-container .selected-circle {
    content: '';
    position: absolute;
    min-height: 80px;
    min-width: 80px;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    display: block;
    border: 3px solid #6523D2;
}
#tutoring .tutor-icon.selected .img-container .selected-circle::after {
    content: '';
    position: absolute;
    min-height: 70px;
    min-width: 70px;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: block;
    border: 2px solid #FFFFFF
}
#tutoring .tutor-icon.selected p {
    color: #6523D2;
}
#tutoring .support-icon .img-container, #tutoring .student-icon .img-container {
    background-color: #6523D2;
}
#tutoring .tutor-icon .img-container .support, #tutoring .tutor-icon.selected .img-container .support {
    min-height: 34px;
    min-width: 34px;
    height: 34px;
    width: 34px;
    border-radius: 0;
}
#tutoring .messages-container, #tutoring .calendar-container {
    padding: 20px;
}
#tutoring .send-new-message {
    width: 100%;
    margin-bottom: 40px;
    display: block;
    top: 10px;
    left: 10px;
}
#tutoring .new-message-box {
    height: 88px;
    margin: 0;
}
#tutoring .message-overview {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DDE6EE;
    border-top: 1px solid #DDE6EE;
    padding: 11px 12px;
    cursor: pointer;
}
#tutoring .message-overview:first-of-type {
    border-top: 2px solid #DDE6EE;
}
#tutoring .message-overview:last-of-type {
    border-bottom: 2px solid #DDE6EE;
}
#tutoring .message-overview.selected {
    background-color: #FAFAFA;
}
#tutoring .message-overview i {
    padding-right: 12px;
}
#tutoring .message-overview p {
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #383B41;
}
#tutoring .message-overview.read p {
    color: #9A9B9C;
}
#tutoring .message-overview .message-subject {
    line-height: initial;
}
#tutoring .message-overview .message-date {
    justify-self: flex-end;
    text-align: right;
    margin-left: auto;
}
#tutoring .message-overview .msg-lhs, #tutoring .message-overview .msg-from {
    display: flex;
}
#tutoring .message-overview .msg-from {
    width: 175px;
}
#tutoring .selected-message-body {
    margin: 20px 0 40px;
}
#tutoring .tutor-tabs {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0 0 12px;
    border-bottom: 1px solid #F0F0F0;
}
#tutoring .tutor-tabs .tutor-tab {
    padding: 6px 18px;
    color: #000000;
    cursor: pointer;
    position: relative;
}
#tutoring .tutor-tabs .tutor-tab.active {
    border-bottom: 2px solid #6523D2;
    font-weight: bold;
}
#tutoring .tutor-tabs .tutor-tab span {
    display: none;
}
#tutoring .tutor-tabs .tutor-tab.unread span {
    display: block;
    position: absolute;
    width: 20px;
    border-radius: 20px;
    padding: 3px 0;
    background-color: #F15A2D;
    top: -5px;
    right: -5px;
    color: #fff;
    text-align: center;
    font-size: 10px;
}
#tutoring .no-tutors {
    display: none;
}
#tutoring .first-session {
    background-color: #6523D2;
    padding: 14px 28px;
    width: fit-content;
    margin: 20px auto 40px;
}
#tutoring .first-session:hover {
    background: #8033FF;
}
#tutoring .first-session:active {
    background: #4C1A9E;
}
#tutoring .fc-direction-ltr .fc-timegrid-col-events {
    margin: 0;
}
#tutoring .fc-daygrid-event, #tutoring .fc-event-main {
    cursor: pointer;
}
#tutoring #add-edit-session, #tutoring #homework, #tutoring #first-session-scheduled {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.75);
    top: 0;
    left: 0;
    z-index: 999;
}
#tutoring #add-edit-session .overlay, #tutoring #homework .overlay, #tutoring #first-session-scheduled .overlay {
    width: 96%;
    height: 96vh;
    max-width: 500px;
    max-height: 550px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    overflow: auto;
    padding: 20px;
    border-radius: 5px;
}
#tutoring #add-edit-session h4 {
    text-transform: none;
    margin-top: 10px;
    margin-bottom: 25px;
    font-size: 18px;
    font-family: Poppins,sans-serif;
    font-weight: 300;
}
#tutoring #add-edit-session .subject-container {
    display: flex;
    align-items: center;
}
#tutoring #add-edit-session .time {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
#tutoring #add-edit-session .session-length {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
#tutoring #add-edit-session .session-length div {
    margin-bottom: 10px;
}
#tutoring #add-edit-session .session-length label {
    margin-left: 5px;
}
#tutoring #add-edit-session .time label, #tutoring #add-edit-session .subject-container label {
    margin-right: 10px;
}
#tutoring #add-edit-session label, #tutoring #add-edit-session input[type=checkbox] {
    cursor: pointer;
}
#tutoring #add-edit-session input, #tutoring #add-edit-session select{
    background-color: #FFFFFF;
    width: auto;
    margin-bottom: 0;
}
#tutoring #add-edit-session .session-btn {
    padding: 12px 18px;
    cursor: pointer;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 25px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    border: none;
}
#tutoring #add-edit-session #schedule-session, #tutoring #add-edit-session #update-status {
    background-color: #ff4081;
    color: #FFFFFF;
}
#tutoring #add-edit-session #update-status {
    display: block;
    margin: 10px 0 0;
}
#tutoring #add-edit-session #delete-session {
    background-color: #D3D3D3;
    color: #000000;
    display: none;
}
#tutoring #add-edit-session .read-only {
    font-size: 14px;
    color: #A9A9A9;
    margin: 20px 0 5px;
}
#tutoring #add-edit-session .cancellation-policy {
    margin-top: 25px;
    font-size: 14px;
    color: #A9A9A9;
}
#tutoring #add-edit-session .exit, #tutoring #homework .exit, #tutoring #first-session-scheduled .exit {
    font-family: "Nunito Sans", Helvetica, Arial, sans-serif;
    position: absolute;
    top: 4px;
    right: 10px;
    content: "x";
    color: #9A9B9C;
    cursor: pointer;
    margin: 0;
    font-size: 24px;
}
#tutoring #homework .overlay {
    padding: 25px 30px;
}
#tutoring #first-session-scheduled .overlay {
    max-width: 400px;
    max-height: 270px;
}
#tutoring #homework h4 {
    font-size: 20px;
    text-align: center;
    text-transform: none;
    border-bottom: 2px solid #6523D2;
    padding-bottom: 7px;
}
#tutoring #homework .no-hw {
    display: none;
    margin-top: 10px;
}
#tutoring #homework .hw-links {
    display: flex;
    flex-direction: column;
}
#tutoring #homework .subj-title {
    margin-top: 15px;
    font-weight: bold;
}
#tutoring #homework .subj-title:first-of-type {
    margin-top: 0;
}
#tutoring #homework .hw-links .hw {
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
}
#tutoring #homework .hw-links .hw .hw-date {
    margin-right: 5px;
}
#tutoring #homework .hw-links .hw .hw-notes {
    width: 100%;
    order: 5;
    font-size: 12px;
    padding-left: 15px;
    padding-bottom: 10px;
}
#tutoring #homework .hw-links .hw .hw-files {
    display: flex;
    flex-direction: column;
}
#tutoring #homework .hw-links .hw .hw-files a:hover, #tutoring .messages-header a:hover, #tutoring .test .test-info a:hover {
    color: #0ca6ff;
    text-decoration: underline;
}
#tutoring #first-session-scheduled h1 {
    margin: 20px 0 40px;
}
#tutoring #first-session-scheduled .btn-row {
    justify-content: center;
    align-items: center;
}
.tutor-select-wrapper {
    background-color: #FBFEFF!important;
    box-shadow: 1px 1px 10px 0 #bbb!important;
    border-radius: 15px!important;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 40px;
}
#tutoring .mini-only {
    display: none;
}
#tutoring .tutor-select .steps-visual {
    display: grid;
    font-size: 10px;
    grid-gap: 1px;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    margin-bottom: 20px;
    width: 100%;
    padding-right: 10px;
}
#tutoring .tutor-select .steps-visual > div {
    background-color: #e9e9e9;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5em;
    font-weight: 700;
    letter-spacing: 0.01em;
    line-height: 1.333em;
    padding: 0.667em 0.667em 0.667em 1.34em;
    position: relative;
    text-decoration: none;
    min-width: 230px;
}
#tutoring .tutor-select .steps-visual > div:before {
    content: '';
    height: 0;
    width: 0;
    border-bottom: 2.18em solid transparent;
    border-left: 1.16em solid #FBFEFF;
    border-top: 2.18em solid transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}
#tutoring .tutor-select .steps-visual > div:after {
    content: '';
    height: 0;
    width: 0;
    border-bottom: 2.18em solid transparent;
    border-left: 1.11em solid;
    border-top: 2.18em solid transparent;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: translateX(1em);
    transform: translateX(1em);
    border-left-color: #e9e9e9;
}
#tutoring .tutor-select .steps-visual > div:nth-child(1) {
    z-index: 3;
}
#tutoring .tutor-select .steps-visual > div:nth-child(2) {
    z-index: 2;
}
#tutoring .tutor-select .steps-visual > div:nth-child(1):before {
    left: -0.1em;
}
#tutoring .tutor-select .steps-visual > div p {
    margin-bottom: 2px;
}
#tutoring .tutor-select .steps-visual .current {
    background-color: #00aa1d;
}
#tutoring .tutor-select .steps-visual .current:after {
    border-left-color: #00aa1d;
}
#tutoring .tutor-select .steps-visual .current > p {
    color: #FFFFFF;
}
#tutoring .tutor-select .steps-visual .past-step {
    cursor: pointer;
}
#tutoring .tutor-select .steps-visual .past-step:hover {
    background-color: #e1e1e1;
}
#tutoring .tutor-select .steps-visual .past-step:hover::after {
    border-left-color: #e1e1e1;
}
#tutoring .tutor-select .steps-visual .current:hover {
    background-color: #00aa1d;
}
#tutoring .tutor-select .steps-visual .current:hover::after {
    border-left-color: #00aa1d;
}
#tutoring .tutor-select .step, #tutoring .tutor-select .step .loaded {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
#tutoring .tutor-select .profile-columns {
    display: flex;
}
#tutoring .tutor-select .profile-columns > div {
    width: 50%;
}
#tutoring .tutor-select .profile-columns > div:first-child {
    border-right: #D7E9F4 solid 1px;
    padding-right: 2%;
}
#tutoring .tutor-select .profile-columns > div:last-child {
    padding-left: 2%;
}
#tutoring .tutor-select input, #tutoring .tutor-select select {
    background: #ffffff;
    color: #383B41;
}
#tutoring .tutor-select select {
    height: 32px;
}
#tutoring .tutor-select input {
    padding: 6px 7px 6px 7px;
}
#tutoring .tutor-select .schedule-preference > div {
    display: flex;
    gap: 2%;
    margin-bottom: 5px;
}
#tutoring .tutor-select .schedule-preference select {
    width: 49%;
    margin-bottom: 0;
}
#tutoring .tutor-select .schedule-preference input {
    margin-bottom: 0;
}
#tutoring .tutor-select .schedule-preference .time-container {
    display: flex;
    flex-direction: column;
    width: 49%;
}
#tutoring .tutor-select .schedule-preference .time-container .sidenote {
    margin-bottom: 0;
}
#tutoring .tutor-select select[name=grade] {
    margin-bottom: 0;
}
#tutoring .tutor-select .sidenote {
    font-size: 12px;
    margin-bottom: 15px;
    color: #7f7f7f;
}
#tutoring .tutor-select .continue-btn {
    padding: 5px 20px;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    background-color: #0211a9;
    border-radius: 3px;
    opacity: 1;
    text-align: center;
    cursor: pointer;
    align-self: end;
}
#tutoring .tutor-select .continue-btn:hover {
    background: #0e1edc;
}
#tutoring .tutor-select .continue-btn:active {
    background: #020d83;
}
#tutoring .tutor-select .continue-btn.disabled, #tutoring .tutor-select .continue-btn.disabled:hover, #tutoring .tutor-select .continue-btn.disabled:active {
    color: #f1f1f1;
    background-color: #b5b5b5;
    cursor: auto;
}
#tutoring .tutor-select .step2, #tutoring .tutor-select .step3 {
    display: none;
}
#tutoring .tutor-select .step2 .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#tutoring .tutor-select .step2 .loading-container p {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
}
#tutoring .tutor-select .step2 .loaded {
    display: none;
}
#tutoring .tutor-list {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    margin: 40px 0 0;
}
#tutoring .tutor-list .tutor-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 32%;
    text-align: center;
    position: relative;
    list-style: none;
    padding: 10px 30px 60px;
    margin: 25px 1%;
    border-radius: 10px;
    border: 2px solid #FFFFFF;
    box-shadow: 0 2px 5px #a9a9a9;
}
#tutoring .tutor-list .tutor-profile:hover {
    border: 2px solid #00aa1d;
    cursor: pointer;
}
#tutoring .tutor-list .tutor-profile.selected {
    border: 2px solid #00aa1d;
    background-color: #f5fff4;
}
#tutoring .tutor-list .tutor-profile.selected:after {
    content: '✔';
    position: absolute;
    top: 10px;
    right: 10px;
    font-family: "Nunito Sans", Helvetica, Arial, sans-serif;
    font-size: 26px;
    color: #00aa1d;
}
#tutoring .tutor-list .tutor-profile:nth-of-type(2n+1), #tutoring .tutor-list .tutor-profile:nth-of-type(2n) {
    margin-left: 1%;
    margin-right: 1%;
}
#tutoring .tutor-list .tutor-profile:nth-of-type(3n+1) {
    margin-left: 0;
}
#tutoring .tutor-list .tutor-profile:nth-of-type(3n) {
    margin-right: 0;
}
#tutoring .tutor-list .profile-image {
    width: 160px;
    height: 160px;
    position: absolute;
    top: -35px;
}
#tutoring .tutor-list .profile-image img {
    margin: 0;
    aspect-ratio: 1;
    object-fit: cover;
    width: 160px;
    height: 160px;
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.15));
    border-radius: 50%;
}
#tutoring .tutor-list .tutor-info {
    padding-top: 125px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#tutoring .tutor-list .tutor-info .tutor-name {
    font-family: Nunito, Quicksand, Helvetica, Arial, sans-serif;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 12px;
}
#tutoring .tutor-list .tutor-info .available-at p {
    margin-bottom: 2px;
    color: #a00;
    font-weight: bold;
}
#tutoring .tutor-list .tutor-info .more-details {
    margin: 10px 5px 0;
    color: #0abfd6;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
}
#tutoring .step2 .show-more {
    align-self: center;
    font-weight: bold;
    margin-bottom: 20px;
}
#tutoring .tutor-select .no-tutors {
    font-weight: bold;
}
.total-sessions {
    margin-bottom: 25px;
}
.total-sessions .section-title {
    margin-bottom: 20px;
}
.total-sessions .session-counts {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}
.total-sessions .session-counts div {
    border-right: 1px solid #DDE6EE;
    flex: 25%;
}
.total-sessions .session-counts div:last-child {
    border-right: none;
}
.total-sessions .session-counts div h2 {
    font-weight: 600;
    font-size: 34px;
    line-height: 28px;
    text-align: center;
    margin: 0;
    color: #383B41;
}
.total-sessions .session-counts div h2 span {
    font-size: 20px;
    vertical-align: baseline;
    display: inline-block;
}
.total-sessions .session-counts div p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin: 0;
}
.total-sessions .conversion {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin: 0 0 20px;
}
.total-sessions .btn-row {
    margin-bottom: 15px;
}
.flatpickr-calendar.open {
    z-index: 999999999 !important;
}
.flatpickr-input {
    width: auto;
    height: 39px;
    cursor: pointer;
    display: inline-block;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: "Nunito Sans", Helvetica, Arial, sans-serif;
}
.calendar .fc-direction-ltr .fc-timegrid-col-events {
    margin: 0;
}
#calendar_0 .fc-timegrid-col-events {
    margin: 0;
}
.calendar a.fc-timegrid-event {
    cursor: default;
}
.calendar .fc-timegrid-slot-minor {
    cursor: pointer;
}
.strike .fc-event-main {
    text-decoration: line-through;
}
.event-tooltip {
    background: #fff;
    color: #000;
    padding: 8px;
    font-size: 12px;
    position: absolute;
    top: 0;
    border-radius: 5px;
    width: 175px;
    z-index: 10001;
    box-shadow: 1px 1px 1px #a9a9a9;
    display: block;
}
.add-file {
    display: block;
    margin: 10px 0;
}
#messages input[type=file] {
    display: block;
    margin-bottom: 5px;
}
#messages .tm-loading {
    width: 100px;
    height: 100px;
}
#messages .messages {
    padding: 20px;
    flex-basis: calc(100% - 250px);
}
#messages .tm-loading {
    height: 100px;
    width: 100px;
    margin: 0 auto;
}
.message-tab-list .message-tab {
    border: 1px solid #d7d7d7;
    border-bottom: none;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    position: relative;
}
.message-tab-list .message-tab.active {
    background: #f4f9f9;
    border-right: none;
}

.message-tab-list .message-tab:last-child {
    border-bottom: 1px solid #d7d7d7;
}
.message-tab .fa-star {
    display: none;
    position: absolute;
    top: 8px;
    right: 0;
    color: #f03f20;
}
#inbox {
    padding-top: 20px;
}
#inbox #messages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
#inbox #messages .messages, #inbox #add-message {
    padding: 20px;
    flex-basis: calc(100% - 250px);
}
#inbox #tutoring .message-container .message:nth-child(n+2) {
    display: block;
}
#inbox #tutoring #messages .messages .message-container:nth-child(n + 2) {
    display: none;
}
#inbox #tutoring #messages .messages .message-container {
    display: none;
}
#inbox #tutoring #messages .messages .message-container.active {
    display: block;
}
#inbox #tutoring .controls {
    display: none;
}
#inbox #tutoring .reply-box {
    display: block;
}
#inbox #tutoring .reply-box.new {
    display: none;
}
#inbox #tutoring .reply-box.new.active {
    display: block;
}
#tutoring .message-tab-list {
    max-height: 800px;
    overflow: auto;
    flex-basis: 250px;
}
#add-message {
    display: none;
}
#tutoring #messages textarea {
    resize: none;
    height: 250px;
}
#tutoring #messages select {
    color: #000;
    font-size: 18px;
    padding: 5px 10px;
    background: #f3fef3;
    height: auto;
}
#tutoring #messages .add-message {
    display: none;
}
#tutoring #messages .select2-container {
    width: 100% !important;
}
#inbox #tutoring #messages .student-name {
    background: #fcfcfc;
    border: solid 1px #310f68;
    font-weight: 500;
    font-size: 18px;
    padding: 7px 1% 6px;
    margin: 0 0 10px;
    min-height: unset;
}
#inbox #tutoring #messages .student-name h1 {
    font-weight: 600;
    font-size: 18px;
    margin-right: 10px;
}
#inbox #tutoring #messages .other-students {
    top: 38px;
    border: solid 1px #310f68;
}
#inbox #tutoring #messages .other-students li {
    padding: 10px;
    font-weight: 500;
}
.glightbox-container .gslide.current {
    height: calc(100% - 120px);
    width: calc(100% - 180px);
}
.glightbox-container .gslide-inner-content {
    width: 100%;
    height: 100%;
}
.gnext, .gprev {
    display: none !important;
}
@media (max-width: 1000px) {
    #tutoring .overview-columns > div:first-child, #tutoring .overview-columns > div {
        flex-basis: 100%;
        border-right: none;
        padding-right: 0;
    }
    #tutoring .col50 {
        width: 100%;
    }
    #tutoring .col50:first-child {
        padding-right: 0;
        border-right: none;
        border-bottom: 1px solid #DDE6EE;
        padding-bottom: 50px;
        margin-bottom: 50px;
    }
    #tutoring .col50:last-child {
        padding-left: 0;
    }
    #tutoring .message-overview .from {
        min-width: 15%;
    }
    #tutoring .message-overview .message-subject {
        margin: 0 15px;
    }
    #tutoring .tutor-select .profile-columns {
        flex-direction: column;
        gap: 25px;
    }
    #tutoring .tutor-select .profile-columns > div {
        width: 100%;
    }
    #tutoring .tutor-select .profile-columns > div:first-child {
        border-right: none;
        padding: 0;
    }
    #tutoring .tutor-select .profile-columns > div:last-child {
        padding: 0;
    }
    #tutoring .tutor-select .step1 .continue-btn {
        order: 1;
    }
    #tutoring .tutor-select .step1 .continue-btn.desktop {
        display: none;
    }
    #tutoring .tutor-select .step2 .continue-btn.bottom {
        order: 1;
    }
    #tutoring .tutor-list .tutor-profile {
        width: 48%;
    }
    #tutoring .tutor-list .tutor-profile:nth-of-type(2n) {
        margin-left: 1%;
        margin-right: 1%;
    }
    #tutoring .tutor-list .tutor-profile:nth-of-type(2n+1) {
        margin-left: 0;
        margin-right: 2%;
    }
    #tutoring .tutor-list .tutor-profile.selected:after {
        top: 7px;
        right: 7px;
    }
}

@media (max-width: 850px) {
    #tutoring .message-overview .msg-lhs {
        flex-direction: column;
    }
    #tutoring .message-overview .msg-from {
        width: auto;
    }
    #tutoring .message-overview .from {
        color: #7c7c7c;
        font-size: 13px;
    }
    #tutoring .message-overview .message-subject {
        margin: 0 15px 0 28px;
    }
}

@media (max-width: 820px) {
    #tutoring .tutor-select .steps-visual > div:nth-child(3):before {
        left: -0.1em;
    }
}

@media (max-width: 660px) {
    #tutoring .message-overview .message-date {
        width: 70px;
        font-size: 12px;
    }
    #tutoring .messages-container, #tutoring .calendar-container {
        padding: 20px 5px;
    }
    .fc .fc-toolbar {
        flex-wrap: wrap;
    }
    .fc .fc-button-group {
        display: block;
        margin-bottom: 5px;
    }
    .fc-toolbar-chunk {
        margin: 0 5px;
    }
    .fc-button-group button {
        width: 100%;
    }
    .fc-direction-ltr .fc-button-group>.fc-button {
        border-radius: 0;
        margin: 1px 0;
    }
    .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child) {
        margin-left: 0;
    }
    .fc-direction-ltr .fc-button-group>.fc-button:first-child {
        border-radius: 4px 4px 0 0;
    }
    .fc-direction-ltr .fc-button-group>.fc-button:last-child {
        border-radius: 0 0 4px 4px ;
    }
    .fc-direction-ltr .fc-button-group>.fc-button.fc-next-button, .fc-direction-ltr .fc-button-group>.fc-button.fc-prev-button {
        width: 48%;
        margin: 1px 1%;
        border-radius: 4px;
    }
}

@media (max-width: 600px) {
    #tutoring {
        padding: 10px;
    }
    #tutoring .overview {
        padding: 10px;
    }
}

@media (max-width: 560px) {
    .fc .fc-button-group {
        display: block;
        margin-bottom: 15px;
    }
    .fc .fc-toolbar {
        display: block;
    }
    .fc-toolbar-chunk {
        text-align: center;
    }
    .fc-direction-ltr .fc-toolbar>*>:not(:first-child), .fc .fc-today-button {
        margin-left: 0;
    }
    .fc .fc-toolbar-title {
        margin: 10px 0;
    }
    .fc-dayGridMonth-view {
        overflow: scroll;
    }
    .fc-dayGridMonth-view > table {
        min-width: 450px;
    }
    .fc-toolbar-chunk {
        margin: 0 5px;
    }
    #tutoring .tutor-select .schedule-preference > div {
        flex-direction: column;
        gap: 5px;
        margin-bottom: 15px;
    }
    #tutoring .tutor-select .schedule-preference .time-container, #tutoring .tutor-select .schedule-preference select {
        width: 100%;
    }
    #tutoring .tutor-list .tutor-profile {
        width: 96%;
    }
    #tutoring .tutor-list .tutor-profile:nth-of-type(2n) {
        margin-left: 2%;
        margin-right: 2%;
    }
    #tutoring .tutor-list .tutor-profile:nth-of-type(2n+1) {
        margin-left: 2%;
        margin-right: 2%;
    }
    #tutoring .tutor-select .continue-btn {
        align-self: center;
    }
    #tutoring .tutor-select .steps-visual > div:before {
        left: -0.1em;
    }
}

@media (max-width: 480px) {
    #tutoring .message-overview {
        padding: 11px 6px;
    }
    #tutoring .message-overview .message-subject {
        margin: 0 10px 0 24px;
    }
    #tutoring .message-overview i {
        padding-right: 8px;
    }
    #tutoring .message-overview .message-date {
        width: auto;
    }
    .fc .fc-timeGridWeek-view table {
        font-size: .95em;
    }
    .fc .fc-col-header-cell-cushion {
        padding: 2px 0;
    }
    .glightbox-container .gslide.current {
        height: calc(100% - 100px);
        width: calc(100% - 20px);
    }
}

@media (max-width: 385px) {
    .fc .fc-timeGridWeek-view table {
        font-size: .9em;
    }
}