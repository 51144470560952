#test-directory h2 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
}
#test-directory .control {
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid #01375d;
    display: flex;
}
#test-directory .control a {
    color: #01375d;
    width: 50%;
    text-align: center;
    border-right: 1px solid #01375d;
    padding: 11px 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
#test-directory .control a:hover {
    background: #c8eaff;
}
#test-directory .vmap-states {
    margin-bottom: 30px;
}
#test-directory .control a:first-of-type {
    border-radius: 8px 0 0 8px;
}
#test-directory .control a:last-of-type {
    border-right: none;
    border-radius: 0 8px 8px 0;
}
#test-directory .control a.active {
    background-color: #01375d;
    color: #fff;
}
#test-directory .listing {
    padding: 25px 0;
}
#test-directory .listing h2 {
    font-size: 18px;
    font-weight: 800;
    line-height: 1.36;
    text-align: left;
    color: #0e5384;
    margin-bottom: 5px;
    border-bottom: 1px solid #0e5384;
    text-transform: capitalize;
}
#test-directory .vmap-states li {
    list-style: none;
}
#test-directory .listing a, #test-directory .vmap-states li a {
    font-size: 16px;
    color: #0e5384;
    display: block;
    margin: 10px 0;
    border: 1px solid #a2bed2;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    font-weight: 800;
    list-style: none;
}
#test-directory .content-container > p:first-of-type {
    text-align: center;
}
#test-directory .listing a:hover, #test-directory .vmap-states li a:hover {
    background: #0e5384;
    color: #fff;
}
#test-directory .listing li {
    list-style: none;
}
#test-directory .lesson-nav {
    display: inline-block;
    width: 31%;
    margin-right: 2%;
}
#test-directory .lesson-content {
    display: inline-block;
    width: 64%;
    margin-left: 2%;
    vertical-align: top;
}
#test-directory .lesson-content p {
    font-size: 16px;
    margin-bottom: 10px;
}
#test-directory .lesson-content img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}
#test-directory .lesson-nav .tablink {
    font-size: 16px;
    background-color: #20a2c0;
    color: #fff;
    padding: 10px   ;
    border-radius: 4px;
    margin: 7px 0;
    display: block;
    border: none;
    text-align: left;
    width: 100%;
    cursor: pointer;
    outline: none;
}
#test-directory .lesson-nav .tablink.active {
    background-color: #164888;
}
#test-directory .lesson-nav .tablink.active:hover {
    background-color: #164888;
}
#test-directory .lesson-nav .tablink:hover {
    background-color: #23adcb;
}
#test-directory .lesson-content h3, #test-directory .lesson-content h4 {
    color: rgb(2, 77, 138);
    margin: 10px 0;
}
#test-directory .lesson-content ol, #test-directory .lesson-content ul {
    margin-left: 25px;
}
#test-directory .tab-pane.active {
    display: block;
}
#test-directory .state-videos {
    padding: 20px 0;
    border-bottom: 1px solid #0e5384;
}
#test-directory .vimeo h2 {
    margin-bottom: 0;
    text-align: center;
}
#test-directory .vimeo iframe {
    width: 100%;
}
#test-directory #stateLinks {
    padding-top: 20px;
}
#test-directory #stateLinks p {
    margin-bottom: 20px;
}
#test-directory #stateLinks li {
    list-style: none;
}
#test-directory .state-disclaimer {
    margin-top: 50px;
    padding-top: 20px;
    border-top: 1px solid #ccc;
}
#test-directory ul.test-list {
    column-count: 3;
    -webkit-column-count: 3;
    -moz-column-count: 3;
}
#test-directory ul.test-list .group {
    margin-bottom: 20px;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;
}

@media (max-width: 1000px) {
    #test-directory ul.test-list {
        column-count: 2;
        -webkit-column-count: 2;
        -moz-column-count: 2;
    }
    #test-directory .lesson-nav {
        width: 100%;
    }
    #test-directory .lesson-nav .tablink {
        padding: 12px 6px;
    }
    #test-directory .lesson-content {
        display: block;
        width: 100%;
        margin-left: 0;
        vertical-align: top;
    }
    #test-directory .state-videos .col50 {
        width: 100%;
        margin: 20px 0;
        float: none;
    }
}

@media (max-width: 800px) {
    #test-directory .vimeo h2 {
        margin-bottom: -7%;
    }
    #stateLinks .col25 {
        width: 48%;
        margin-bottom: 10px;
    }
}

@media (max-width: 560px) {
    #test-directory ul.test-list {
        column-count: 1;
        -webkit-column-count: 1;
        -moz-column-count: 1;
    }
    #test-directory .vimeo h2 {
        margin-bottom: -12%;
    }
}

@media (max-width: 450px) {
    #test-directory .control {
        display: block;
    }
    #test-directory .control a {
        display: block;
        width: 100%;
    }
    #test-directory .control a:first-of-type {
        border-radius: 8px 8px 0 0;
    }
    #test-directory .control a:last-of-type {
        border-radius: 0 0 8px 8px;
    }
}
