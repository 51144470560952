@media screen {
    @font-face {
        font-family: 'Nunito Sans';
        src: url("/font/NunitoSans-Regular.ttf");
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Nunito Sans';
        src: url("/font/NunitoSans-Italic.ttf");
        font-weight: normal;
        font-style: italic;
    }
    @font-face {
        font-family: 'Nunito Sans';
        src: url("/font/NunitoSans-Light.ttf");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Nunito Sans';
        src: url("/font/NunitoSans-LightItalic.ttf");
        font-weight: 300;
        font-style: italic;
    }
    @font-face {
        font-family: 'Nunito Sans';
        src: url("/font/NunitoSans-SemiBold.ttf");
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: 'Nunito Sans';
        src: url("/font/NunitoSans-SemiBoldItalic.ttf");
        font-weight: 600;
        font-style: italic;
    }
    @font-face {
        font-family: 'Nunito Sans';
        src: url("/font/NunitoSans-Bold.ttf");
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: 'Nunito Sans';
        src: url("/font/NunitoSans-BoldItalic.ttf");
        font-weight: 700;
        font-style: italic;
    }
    @font-face {
        font-family: 'Nunito';
        src: url("/font/Nunito-Regular.ttf");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Nunito';
        src: url("/font/Nunito-Bold.ttf");
        font-weight: 700;
        font-style: normal;
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    #root {
        width: 100%;
        height: 100%;
    }
    html {
        width: 100%;
        height: 100%;
    }
    body {
        font-family: 'Nunito Sans', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #e5f3fc;
        width: 100%;
        height: 100%;
        text-decoration: none;
    }
    a {
        text-decoration: none;
        color: #0e5384;
    }
    h1, h2 {
        font-size: 32px;
        color: #383b41;
        line-height: 1.3;
        margin-bottom: 24px;
        font-family: "Nunito", Helvetica, Arial, sans-serif;
        text-align: center;
    }
    h3 {
        font-family: "Nunito", Helvetica, Arial, sans-serif;
        font-size: 1.2em;
        color: #3c3c3c;
        margin-bottom: 15px;
        line-height: normal;
    }
    h4 {
        font-family: "Nunito", Helvetica, Arial, sans-serif;
        font-size: 1.1em;
        color: #3c3c3c;
        margin-bottom: 10px;
        line-height: normal;
    }
    .col100 {
        width: 98%;
        margin: 0 1%;
        float: left;
        position: relative;
    }
    .col75 {
        width: 73%;
        float: left;
        position: relative;
    }
    .col66 {
        width: 65%;
        margin: 0 1%;
        float: left;
        position: relative;
    }
    .col50 {
        width: 48%;
        margin: 0 1%;
        float: left;
        position: relative;
    }
    .col33 {
        width: 31%;
        margin: 0 1%;
        float: left;
        position: relative;
    }
    .col25 {
        width: 23%;
        margin: 0 1%;
        float: left;
        position: relative;
    }
    .col60 {
        width: 58%;
        margin: 0 1%;
        float: left;
        position: relative;
    }
    .col40 {
        width: 38%;
        margin: 0 1%;
        float: left;
        position: relative;
    }
    .left {
        float: left;
    }
    .right {
        float: right;
    }
    .clear {
        clear: both;
    }
    div.content {
        position: absolute;
        width: calc(100% - 420px);
        left: 210px;
        top: 220px;
        min-height: 60vh;
        min-height: calc(100vh - 220px);
        background: #fff;
    }
    .hide {
        display: none;
    }
    .bold {
        font-weight: 700;
    }
    .sidebarRight {
        position: absolute;
        top: 25px;
        right: -210px;
        width: 210px;
        padding: 10px 8px;
        color: #fff;
    }
    .sidebarRight .sidebar-section {
        margin-bottom: 40px;
    }
    .sidebarRight h3 {
        color: #fff;
        font-size: 18px;
        border-bottom: 1px solid #fff;
    }
    .sidebarRight h3 svg {
        vertical-align: bottom;
    }
    .sidebarRight p {
        color: #fff;
        margin-bottom: 15px;
    }
    .sidebarRight a {
        color: #fff;
        display: block;
    }
    .dashboard {
        background: #fff;
        padding: 20px 30px 30px;
    }
    .green-heading {
        color: #17b7a9;
        border-bottom: 2px solid #17b7a9;
    }
    .page-header {
        position: relative;
        text-align: center;
        height: 55px;
        width: 100%;
        color: #fff;
        background: #f15a2d;
        text-shadow: 1px 1px 1px #777;
    }
    .page-header h1 {
        color: #fff;
        margin: 0;
        padding-top: 7px;
        text-shadow: 1px 1px 1px #555;
    }
    .page-header.child-navigation {
        background: #17b7a9;
        font-weight: 600;
        z-index: 3;
    }
    .content-container {
        background: #fff;
        position: relative;
        z-index: 3;
        min-height: 100px;
    }
    .child-navigation p {
        font-size: 18px;
        display: inline-block;
        margin: 0px;
        padding: 18px 25px 15px;
        cursor: pointer;
    }
    .child-navigation p:hover {
        background: #18a296;
    }
    .child-navigation p.active {
        background-color: #176d65;
    }
    .child-navigation a {
        color: #fff;
        position: absolute;
        font-size: 14px;
    }
    .child-navigation a.active {
        border-bottom: 5px solid #fff;
    }
    .child-navigation a:hover {
        text-decoration: underline;
    }
    .child-navigation a {
        top: 5px;
        right: 10px;
    }
    .child-navigation svg.svg-icon, .child-navigation p span {
        display: inline-block;
        vertical-align: text-bottom;
    }
    .styled-select select {
        border: none;
        background-color: #fff;
        color: #6b6f81;
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        cursor: pointer;
        margin-left: 0;
        height: 25px;
        font-size: 16px;
        outline: none;
    }
    .styled-select {
        position: relative;
        padding: 4px 0 4px 5px;
        border-radius: 3px;
        border: solid 1px #0e5384;
    }
    .styled-select:after {
        content: '';
        font-weight: 900;
        position: absolute;
        font-size: 16px;
        border-radius: 0 3px 3px 0;
        top: 15px;
        right: 8.5px;
        color: #0e5384;
        pointer-events: none;
        background-color: #fff;
        width: 0;
        height: 0;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
        border-top: 7px solid #0e5384;
    }
    .italic {
        font-style: italic;
    }
    .cont {
        border: 1px solid #e7ebf1;
        padding: 20px;
        margin: 20px 0;
    }
    header {
        width: 100%;
        padding: 0 5px;
        border-bottom: 1px solid #ddd;
        background: #fff;
        z-index: 999;
    }
    header .logo {
        padding: 5px;
    }
    header .logo img {
        vertical-align: bottom;
        height: 40px;
    }
    header li {
        display: block;
        margin: 7px 0;
        font-size: 14px;
        padding: 0 15px;
        position: relative;
    }
    header li a {
        color: #0b3d72;
        text-decoration: none;
    }
    header li a:hover {
        text-decoration: underline;
    }
    header li .new-message .env {
        font-size: 20px;
    }
    header li .new-message .env, header li .new-message .star {
        position: absolute;
    }
    header li .new-message {
        display: none;
        position: relative;
        vertical-align: top;
        margin-left: 15px;
    }
    header li .new-message .star {
        top: -8px;
        left: 11px;
        font-size: 16px;
        color: #f03f20;
    }
    header #menuButton {
        width: 91px;
        height: 34px;
        border-radius: 3px;
        border: solid 1px #0e5384;
        background-color: #ffffff;
        margin: 8px 4px;
        cursor: pointer;
    }
    header #menuButton > span:first-child {
        width: 40px;
        height: 22px;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        margin: 6px 8px;
        color: #0e5384;
    }
    header #menuButton span {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        text-align: right;
        color: #0e5384;
        cursor: pointer;
    }
    header #menuButton > p:first-child {
        font-size: 12px;
        margin: 5px 0;
        color: #555;
    }
    header #menuButton .menu-bars {
        font-size: 18px;
        margin-left: 3px;
    }

    header menu .new-message {
        position: absolute;
        display: none;
        top: 1px;
        right: 90px;
    }
    header menu .new-message span {
        position: absolute;
    }
    header menu .new-message .star {
        color: #f03f20;
    }
    header menu .new-message p {
        display: none;
        position: absolute;
        width: 90px;
        font-size: 15px;
        text-align: center;
        left: 11px;
        top: 9px;
        background: #fff;
        box-shadow: 0 0 2px #999;
        padding: 2px;
        height: auto;
    }
    header ul {
        position: absolute;
        z-index: 5;
        background: #fff;
        border: 1px solid #ccc;
        border-top: none;
        border-bottom-width: 0;
        top: 50px;
        right: 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
    }
    header #search-results ul {
        right: 247px;
    }
    header form input {
        display: none;
        width: calc(100% - 60px);
        border: none;
        font-size: 16px;
        padding: 2px 6px;
        margin-top: 5px;
    }
    header form input[name=search]:focus{
        outline: none;
    }
    header form {
        width: 52.6px;
        height: 34px;
        border-radius: 20px;
        border: solid 1px #0e5384;
        background-color: #ffffff;
        text-align: center;
        margin: 8px 10px 0 0;
        transition: width 500ms;
    }
    header button {
        -webkit-appearance: none;
        background: none;
        border: none;
        outline: none;
        vertical-align: middle;
        float: right;
        margin-right: 10px;
    }
    header .questions-call {
        font-size: 15px;
        color: #0e5384;
        padding: 6px;
        border-right: 1px solid #0e5384;
        border-left: 1px solid #0e5384;
        margin-right: 7px;
        text-align: center;
    }
    header .questions-call span {
        margin-right: 10px;
    }
    header .questions-call .fa-phone {
        margin-right: 5px;
        transform: rotate(100deg);
        font-size: 10px;
        display: inline-block;
    }
    header #searchForm .search {
        color: #0e5384;
        font-size: 20px;
        margin-top: 5px;
        margin-right: 5px;
    }
    header .btn {
        background: #0f5383;
        color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
        margin: 8px 10px;
        font-weight: bold;
        border: 1px solid #0f5383;
    }
    header .btn.interactive {
        background: #218fdc;
        border-color: #218fdc;
    }
    header .btn.tutoring {
        background: #0b821078;
        border-color: #0b821078;
    }
    header #menu .btn {
        display: none;
    }
    header .btn:hover {
        background: #fff;
        color: #0f5383;
    }
    nav {
        width: 210px;
        position: absolute;
    }
    nav > ul li {
        position: relative;
        list-style: none;
        background-color: #e5f3fc;
    }
    nav li.break {
        height: 1px;
        width: 80%;
        margin: 10px auto;
        background: #0e5384;
    }
    nav ul a {
        justify-content: left;
        padding: 5px 10px;
        color: #0e5384;
        text-decoration: none;
        align-items: center;
        font-size: 15px;
        display: block;
    }
    nav > ul > li > a {

        width: 100%;
        min-height: 40px;

    }
    nav li a:hover {
        background: #c8eaff;
    }
    nav li a.active {
        background: #01375d;
        color: #fff;
    }
    nav li a.child {
        padding-top: 6px;
    }
    nav li a svg.svg-inline--fa {
        margin: 8px auto 0;
        font-size: 22px;
        display: block;
    }
    nav li a span {
        display: inline-block;
        vertical-align: middle;
    }
    nav li a span.svg {
        height: 100%;
        width: 30px;
        color: #0e5384;
        margin-right: 5px;
    }
    nav li a span.svg .external-link {
        width: 1em;
    }
    nav a.external span.svg:last-of-type {
        margin-top: -9px;
        height: auto;
    }
    nav li:nth-child(n + 2) a span.svg:first-of-type {
        background-image: url('/img/menu-icon-sprite-3.png');
        background-size: 100%;
        width: 30px;
        height: 30px;
    }
    nav .camps a span.svg {
        background-position: 0 -390px;
    }
    nav .classes a span.svg {
        background-position: 0 0
    }
    nav .tour a span.svg {
        background-position: 0 -30px;
    }
    nav .free-questions a span.svg {
        background-position: 0 -450px;
    }
    nav .test-prep a span.svg {
        background-position: 0 -60px;
    }
    nav .sba a span.svg {
        background-position: 0 -90px;
    }
    nav .resources a span.svg {
        background-position: 0 -120px;
    }
    nav .enrichment.Summer a span.svg {
        background-position: 0 -150px;
    }
    nav .enrichment.Fall a span.svg {
        background-position: 0 -180px;
    }
    nav .enrichment.Winter a span.svg {
        background-position: 0 -210px;
    }
    nav .enrichment.Spring a span.svg {
        background-position: 0 -240px;
    }
    nav .games a span.svg {
        background-position: 0 -270px;
    }
    nav .store a span.svg {
        background-position: 0 -300px;
    }
    nav .iqfp a span.svg {
        background-position: 0 -330px;
    }
    nav .gogo a span.svg {
        background-position: 0 -360px;
    }
    nav .kindergarten a span.svg {
        background-position: 0 -420px;
    }
    nav .coding-academy a span.svg {
        background-position: 0 -480px;
    }
    nav .tutoring a span.svg {
        background-position: 0 -510px;
    }
    nav .tutoring ul.active {
        display: block;
    }
    nav .tutoring ul.active a.active {
        background: transparent;
        color: #0e5384;
        font-weight: bold;
    }
    nav li a span.avatar {
        background: #fff;
        border-radius: 100%;
        height: 28px;
        width: 28px;
        margin-right: 7px;
        text-align: center;
    }
    nav li a span.avatar.add-student {
        background-color: transparent;
    }
    nav li a span.avatar.add-student svg {
        margin-top: 2px;
        background-color: transparent;
    }
    nav li a span.avatar img {
        max-width: 100%;
    }
    nav li a span:nth-child(2) {
        width: 155px;
    }
    nav li a.external span:nth-child(2) {
        width: 110px;
    }
    nav li a.active span svg path {
        color: #fff;
        fill: #fff;
    }
    nav li ul {
        display: none;
    }
    nav li:first-of-type a span.svg {
        margin-top: -3px;
    }
    nav li:first-child a span:nth-child(2) {
        padding-top: 5px;
    }
    nav > ul {
        background-color: #e5f3fc;
    }
    a.buy-now {
        color: #fff !important;
        text-align: center;
        height: auto;
    }
    a.buy-now span {
        background-color: #e94218;
        font-size: 18px;
        font-weight: 800;
        width: 100%;
        padding: 7px;
        border-radius: 8px;
    }
    .four04 {
        height: calc(100vh - 220px);
        padding: 100px;
        text-align: center;
    }
    #sidebarLeft {
        width: 210px;
    }
    #sidebarLeft .js-Dropdown {
        width: 100%;
        height: 100%;
        position: absolute;
        color: #01375d;
        font-size: 16px;
    }
    #sidebarLeft .js-Dropdown button {
        color: #01375d;
        font-size: 16px;
    }
    #sidebarLeft .js-Dropdown-title:after {
        border-color: #01375d transparent transparent transparent;
        top: 40%;
    }
    #sidebarLeft .js-Dropdown-list {
        max-height: 100%;
        overflow: auto;
        height: 400px;
        background: #fff;
        border-bottom: 1px solid #a5a5a5;
    }
    #sidebarLeft .js-Dropdown-list li:first-child {
        display: none;
    }
    #sidebarNav > ul {
        margin-top: 48px;
    }
    #sidebarRight {
        position: absolute;
        top: 55px;
        right: -210px;
        width: 210px;
        padding: 10px 0;
        color: #0e5384;
        background-color: #e5f3fc;
    }
    #sidebarRight .sidebar-section {
        margin-bottom: 40px;
    }
    #sidebarRight h3 {
        color: #0e5384;
        font-size: 18px;
        padding: 0 15px;
    }
    #sidebarRight h3 svg {
        vertical-align: bottom;
    }
    #sidebarRight p {
        color: #0e5384;
        margin-bottom: 15px;
        padding: 0 15px;
    }
    #sidebarRight a {
        color: #0e5384;
        display: block;
    }
    #sidebarRight .sidebar-contact {
        background-color: #0e5384;
        color: #fff;
        padding: 15px;
    }
    #sidebarRight .sidebar-contact p {
        font-weight: 700;
    }
    #sidebarRight .sidebar-contact p, #sidebarRight .sidebar-contact a,
    #test-dashboard #sidebarRight .sidebar-contact a:last-of-type {
        color: #fff;
        padding: 0;
        margin: 0;
    }
    #sidebarRight .sidebar-contact a:last-of-type, #test-dashboard #sidebarRight .sidebar-contact a:last-of-type {
        font-size: 12px;
    }
    #expand-left {
        right: auto;
        left: 0;
        color: #0e5384;
        background: #cee8f9;
        position: absolute;
        top: 0;
        width: 40px;
        height: 100vh;
        cursor: pointer;
        display: none;
        z-index: 9;
    }
    #expand-left svg {
        position: absolute;
        left: 10px;
        top: 6px;
        font-size: 36px;
        z-index: 3;
        width: 24px!important;
    }
    #expand-left svg {
        transform: rotate(180deg);
        left: 8px;
    }
    #expand-left svg, #expand-left path {
        pointer-events: none;
    }
    #expand-left p {
        color: #0e5384;
        position: absolute;
        top: 69px;
        font-weight: 800;
        font-size: 12px;
    }
    #expand-left p:first-of-type {
        left: 5px;
    }
    #expand-left.open p:nth-of-type(2) {
        left: 9px;
    }
    .arrow.right {
        transform: rotate(180deg);
    }

    .star-key {
        position: relative;
        width: auto;
        display: inline-block;
        margin-left: 15px;
    }
    .star-key span {
        margin-left: 40px;
    }
    .star-key .star {
        margin: 0;
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-right: 16px solid transparent;
        border-left: 16px solid transparent;
        transform: rotate(35deg);
        top: 5px;
        left: 0;
    }
    .star-key .star:before {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        height: 0;
        width: 0;
        top: -8px;
        left: -11.5px;
        display: block;
        content: '';
        transform: rotate(-35deg);
    }
    .star-key .star:after {
        position: absolute;
        display: block;
        top: 1px;
        left: -17.5px;
        width: 0;
        height: 0;
        border-right: 16px solid transparent;
        border-left: 16px solid transparent;
        transform: rotate(-70deg);
        content: '';
    }
    .star.orange:before {
        border-bottom: 13px solid #ee5c28;
    }
    .star.orange:after {
        color: #ee5c28;
        border-bottom: 11px solid #ee5c28;
    }
    .star.orange {
        color: #ee5c28;
        border-bottom: 11px solid #ee5c28;
    }
    .star.green:before {
        border-bottom: 13px solid #449f4e;
    }
    .star.green:after {
        color: #449f4e;
        border-bottom: 11px solid #449f4e;
    }
    .star.green {
        color: #449f4e;
        border-bottom: 11px solid #449f4e;
    }
    .star.blue:before {
        border-bottom: 13px solid #0b76b4;
    }
    .star.blue:after {
        color: #0b76b4;
        border-bottom: 11px solid #0b76b4;
    }
    .star.blue {
        color: #0b76b4;
        border-bottom: 11px solid #0b76b4;
    }
    .thumbnail-link .star {
        margin: 0;
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-right: 16px solid transparent;
        border-left: 16px solid transparent;
        transform: rotate(35deg);
        top: 15px;
        right: 3px;
    }
    .thumbnail-link .star:before {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        height: 0;
        width: 0;
        top: -8px;
        left: -11.5px;
        display: block;
        content: '';
        transform: rotate(-35deg);
    }
    .thumbnail-link .star:after {
        position: absolute;
        display: block;
        top: 1px;
        left: -17.5px;
        width: 0;
        height: 0;
        border-right: 16px solid transparent;
        border-left: 16px solid transparent;
        transform: rotate(-70deg);
        content: '';
    }
    .thumbnail-link .star-bg {
        position: absolute;
        top: 10px;
        left: 0;
        background-color: #fff;
        padding: 20px;
    }
    .videoWrapper {
        position: relative;
        width: 100%;
        padding-bottom: 49.9%;
        background: #000;
    }
    .videoWrapper iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    #call-us {
        position: fixed;
        bottom: 0;
        right: 10px;
        background: #b809bf;
        color: #fff;
        font-size: 14px;
        padding: 10px;
        border-radius: 4px 4px 0 0;
        z-index: 9990;
        height: 38px;
    }
    #call-us a {
        color: #fff;
        font-size: 16px;
    }
    #call-us .fa-phone {
        margin-right: 10px;
        transform: rotate(100deg);
    }
    .practice-btn {
        padding: 5px 10px;
        background: #0e5384;
        color: #fff;
        border: solid 1px #0e5384;
        font-size: 16px;
        font-weight: 600;
        border-radius: 3px;
        text-decoration: none;
        cursor: pointer;
        line-height: 1.5;
    }
    .practice-btn:hover {
        background-color: #fff;
        color: #0e5384;
    }
    button.next10 {
        position: absolute;
        top: 11px;
        right: 40px;
        font-size: 20px;
        background: #17b7a9;
        color: #fff;
        border: none;
        padding: 10px 40px;
        border-radius: 5px;
        font-weight: 700;
        cursor: pointer;
    }
    .finished100 button.next10 {
        bottom: 35px;
        left: calc(50% - 150px);
        width: 300px;
        font-size: 30px;
        background: #17b7a9;
        color: #fff;
        border: none;
        padding: 15px 40px;
        top: auto;
        right: auto;
    }
    .buy-now-sidebar {
        width: 100%;
    }
    .buy-now:hover {
        opacity: 0.8;
    }
    .BeaconFabButtonFrame {
        bottom: 40px !important;
    }
    #react-confirm-alert .react-confirm-alert-overlay {
        z-index: 9999;
    }
    .highz {
        z-index: 9999;
    }
    .back-to-dash {
        margin-bottom: 15px;
        display: inline-block;
        text-decoration: underline;
    }
    .back-to-dash svg {
        margin-right: 3px;
        font-size: 14px;
    }
    #site-tour {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.75);
        height: 100%;
        width: 100%;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #site-tour .close {
        position: absolute;
        border-radius: 50px;
        font-size: 40px;
        color: #fff;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
    #site-tour .close svg {
        display: block;
    }
    #site-tour .videoWrapper {
        width: 80%;
        padding-bottom: 39%;
    }

    @media (max-width: 1240px) {
        body {
            overflow-x: hidden;
        }
        div.content {
            width: calc(100% - 210px);
        }
        #sidebarRight {
            position: absolute;
            right: -40px;
            background-color: #e5f3fc;
            width: 40px;
            padding-top: 50px;
            transition: 0.4s ease;
            word-break: break-all;
            display: none;
        }
        #sidebarRight.open {
            display: block;
            right: -40px;
            transition: 0.4s ease;
            width: 210px;
        }
        #sidebarRight .sidebar-section {
            display: none;
        }
        #sidebarRight.open .sidebar-section {
            display: block;
        }
        #sidebarRight h3 svg {
            display: none;
        }
        #sidebarRight.open h3 svg {
            display: inline-block;
        }
    }
    @media (max-width: 1100px) {
        .child-navigation p {
            margin: 0;
            font-size: 18px;
            display: block;
            padding: 6px;
        }
        .page-header.child-navigation {
            padding: 5px 0 0;
            border-bottom: 5px #17b7a9 solid;
        }
        .page-header {
            height: auto;
        }
        header > .btn {
            display: none;
        }
        header #menu .btn {
            display: block;
            width: 196px;
            text-align: center;
            margin: 0;
        }
    }
    @media (max-width: 950px) {
        button.next10 {
            right: 20px;
        }
    }
    @media (max-width: 800px) {
        .dashboard {
            padding: 20px 10px 30px;
        }
    }
    @media (min-width: 801px) {
        #expand-left {
            display: none!important;
        }
        #sidebarLeft {
            left: 0!important;
        }
    }

    @media (max-width: 800px) {
        #sidebarLeft {
            width: 40px;
            position: absolute;
            transition: 0.4s linear;
            left: -260px;
            z-index: 4;
        }
        nav {
            width: 260px;
        }
        nav li a span:nth-child(2) {
            width: 205px;
        }
        nav li a.external span:nth-child(2) {
            width: 160px;
        }
        div.content {
            width: calc(100% - 40px);
            left: 40px;
        }
        #expand-left {
            display: block;
            transition: 0.4s linear;
            left: 260px;
        }
        #expand-left.open p:first-of-type {
            display: none;
        }
        #expand-left.open p:nth-of-type(2) {
            display: block;
        }
        #sidebarLeft.open nav {
            display: block;
            position: absolute;
            left: 0;
            background-color: #e5f3fc;
            z-index: 2;
            overflow-y: auto;
        }
        #sidebarLeft.open svg {
            transform: rotate(0);
        }
        header .questions-call {
            display: none;
        }
        header #search-results ul {
            right: 110px;
        }
    }
    @media (max-width: 660px) {
        header .logo-1 {
            display: none;
        }
        header .logo-3 {
            display: block;
        }
        button.next10 {
            font-size: 18px;
            padding: 5px 20px;
            right: 12px;
            top: 6px;
        }
    }
    @media (max-width: 550px) {
        #avatar-overlay .popup {
            width: 98%;
        }
        #avatar-overlay .avatar-select {
            height: 250px;
        }
        .page-header.child-navigation {
            padding-top: 25px;
            margin-top: -1px;
        }
    }
    @media (max-width: 500px) {
        header form.opened {
            width: 90%!important;
            position: absolute;
            top: 53px;
            right: 0;
            z-index: 9;
            margin: 0 5%;
        }
        header #search-results ul {
            right: 5%;
            top: 86px;
            width: 90%;
        }
    }
    @media (max-width: 450px) {
        div.content {
            /*width: calc(100% - 80px);*/
            left: 40px;
        }
        #expand-left {
            width: 40px;
        }
        #expand-left svg {
            width: 20px!important;
        }
        #avatar-overlay .avatar {
            width: 31%;
        }
        #avatar-overlay .avatar-select {
            padding: 5px 0;
        }
    }
    @media (max-width: 400px) {
        header .logo {
            padding: 10px 5px;
        }
        header .logo img {
            height: 30px;
        }
        header form {
            margin: 8px 4px 0;
        }
        header .menu span:first-child {
            margin: 6px 5px;
        }
        header .menu {
            width: 70px;
        }
        header .menu span, header .menu div {
            font-size: 14px;
        }
        header .menu span svg {
            width: 18px;
            height: 13px;
        }
    }
    @media(max-height: 800px) {
        #avatar-overlay .popup {
            margin: 25px auto;
            height: 90%;
            padding: 5px 10px;
        }
        #avatar-overlay .avatar-select {
            height: 50%;
        }
        #avatar-overlay .popup h2 {
            font-size: 28px;
        }
        #avatar-overlay .switch-popup {
            font-size: 16px;
            padding: 5px 0;
            width: 40%;
        }
    }
}
