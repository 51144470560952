#skill-building {
    position: relative;
    min-height: 100vw;
    z-index: 3;
}
#skill-building h1 {
    margin-top: 10px;
}
#skill-building .programs {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #155387;
    border-bottom: 1px solid #155387;
    margin-top: 20px;
}
#sba-sign-up select {
    color: #000;
    text-shadow: none;
    -webkit-appearance: menulist-button;
    background: #fff;
    border: 2px solid #0b76b4;
    margin-bottom: 24px;
    height: 35px;
    font-size: 1em;
    width: 190px;
    text-align: center;
    display: block;
}
#skill-building p.account-note {
    font-size: 14px;
    margin: 20px 0;
}
#skill-building .lgray-text {
    font-style: italic;
    font-size: 12px;
    font-weight: bold;
    color: #8f9090;
}
#skill-building .btn {
    width: 100%;
    color: #fff;
    font-size: 1em;
    text-align: center;
    padding: 14px 0;
    text-decoration: none;
    display: block;
    cursor: pointer;
    text-shadow: 1px 1px 1px #999;
    border-radius: 3px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 auto;
}
#skill-building .sba-single > .btn:nth-of-type(2) {
    margin-top: 40px;
}
#skill-building .bkg_org {
    background: #f15a2d;
}
#skill-building .bkg_dk_bl {
    background: #006dae;
}
#skill-building .bkg_grn {
    background: #0ca04b;
}
#skill-building .bkg_red {
    background: #d31800;
}
#skill-building #sba-link span {
    display: block;
    padding: 36px;
    font-weight: bold;
}
#skill-building #sba-link {
    color: white;
    font-size: 28px;
    position: relative;
    margin: 100px auto;
    text-transform: uppercase;
    display: block;
    text-align: center;
}
#skill-building .clear {
    clear: both!important;
    float: none!important;
    margin: 0 auto!important;
}
#skill-building .sba-single iframe {
    width: 100%;
}
#skill-building .sba-single .error {
    margin-top: 20px;
    text-align: left;
    color: #f00;
    font-weight: bold;
}
#skill-building .bkg_dk_bl.login-info {
    max-width: 430px;
}
#sba-sign-up {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
}
#sba-sign-up #bkg {
    background: #000;
    opacity: .5;
    filter: alpha(opacity=50);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}
#sba-sign-up .round {
    position: absolute;
    z-index: 9;
    left: 50%;
    top: 50%;
    width: 300px;
    min-height: 380px;
    margin: -190px 0 0 -150px;
    -webkit-box-shadow: 3px 2px 5px rgba(0,0,0,0.3);
    -moz-box-shadow: 3px 2px 5px rgba(0,0,0,0.3);
    box-shadow: 3px 2px 5px rgba(0,0,0,0.3);
    color: #6f6f6f;
    border: 1px solid #e7ebf1;
    padding: 20px;
    background-color: #fff;
}
#sba-sign-up .round #round2, .round #round3, .round #round4 {
    display: none;
}
#sba-sign-up .round #round1, .round #round3, .round #round4 {
    background: #fff;
    color: #000;
    font-size: 16px;
    text-align: center;
    position: absolute;
    margin: -20px 0 0 -20px;
    width: 100%;
    height: 100%;
    padding: 30px 10px;
}
#sba-sign-up .round #round4 {
    color: #b00;
}
#sba-sign-up .round #round1 p:last-child {
    background: #69F;
    color: #fff;
    padding: 10px;
    width: 80%;
    margin: 20px auto;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;
    border-radius: 5px;
}
#sba-sign-up .round #round4 p:last-child {
    cursor: pointer;
    text-decoration: underline;
}
#sba-sign-up .round #round4{
    color: #b00;
}
#sba-sign-up .round #round2 p:first-child {
    text-align: center;
}
#sba-sign-up .round #round2 p img {
    max-width: 100px;
}
#sba-sign-up .round input[type=submit]{
    color: #fff;
    font-weight: bold;
    margin-top: 5px;
    cursor: pointer;
    float: right;
}
#sba-sign-up .round input[type=submit]:hover{
    opacity: .7;
    filter: alpha(opacity=70);
}
#sba-sign-up .round input[type=text]{
    padding: 2px 4px;
}
#sba-sign-up .round input[type=text], input[type=password], textarea {
    display: block;
    background: #f1f1f2;
    margin-bottom: 15px;
    padding: 7px 1% 6px 1%;
    font-family: "Nunito Sans", Helvetica, Arial, sans-serif;
    color: #333;
    width: 100%;
    border: 1px solid #dbe1e9;
    box-sizing: border-box;
    font-size: 14px;
}
#sba-sign-up .round select {
    border: 1px solid #dbe1e9;
    background: #f1f1f2;
    width: 100%;
    color: #888;
    text-indent: 1%;
    margin-bottom: 15px;
    box-sizing: border-box;
    font-size: 14px;
}
#sba-sign-up .round .btn {
    width: 125px;
    border: none;
    border-radius: 5px;
}
#skill-building .thumbnail-link .title {
    padding-right: 0;
    text-align: center;
    font-size: 18px;
}
#skill-building .bkg_grn.btn, #skill-building .btn.bkg_org {
    width: 430px;
    padding: 20px;
}
#skill-building .key {
    margin-top: 8px;
}
#skill-building .thumbnail-link {
    display: block;
    flex-basis: 19%;
    margin: 0 0.5% 20px;
    border: none;
    text-align: center;
}
#skill-building .thumbnail-link .description {
    display: none;
}
#skill-building .thumbnail-link img {
    float: none;
}
#skill-building .watch {
    position: absolute;
    z-index: 9;
    right: 10px;
    top: 10px;
    cursor: pointer;
    text-decoration: underline;
}
#skill-building .thumbnail-link .star-bg {
    left: 50%;
    margin-left: -80px;
    top: 5px;
    background-color: transparent;
}

@media (max-width: 1300px) {
    #skill-building .thumbnail-link {
        width: 96%;
        margin-left: 2%;
    }
}

@media (max-width: 1050px) {
    .thumbnail-link .title {
        font-size: 14px;
    }
}

@media (max-width: 1000px) {
    #skill-building .key {
        float: left;
        clear: both;
        margin: 20px 0 10px;
    }
    #skill-building .star-key:first-of-type {
        margin-left: 0;
    }
}

@media (max-width: 900px) {
    #skill-building .key.right, #skill-building .subjects.left {
        float: none;
        display: block;
    }
    #skill-building .key.right {
        margin-bottom: 30px;
    }
    #skill-building .star-key:first-of-type {
        margin-left: 0;
    }
}

@media (max-width: 800px) {
    #skill-building #sba-link {
        font-size: 20px;
    }
    #skill-building #sba-link span {
        padding: 10px 20px;
    }
    #skill-building .bkg_grn.btn {
        width: 100%;
        font-size: 14px;
    }
    .thumbnail-link .star-bg {
        top: 20px;
    }
}

@media (max-width: 600px) {
    .star-key {
        margin-left: 0;
        display: block;
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    #skill-building .star-key {
        margin-left: 0;
        display: block;
    }
    #skill-building .star-key:first-of-type {
        margin-bottom: 10px;
    }
    #skill-building #sba-link {
        width: 80%;
    }
    #skill-building #sba-link img {
        width: 100%;
    }
    #skill-building .thumbnail-link .title {
        padding-right: 0;
    }
}
