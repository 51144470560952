#parent-resources {
    background: #fff;
    position: relative;
    z-index: 3;
}
#parent-resources h1 {
    color: #0e5384;
    border-bottom: 2px solid #0e5384;
    margin-bottom: 12px;
    font-size: 24px;
    padding-bottom: 5px;
}
#parent-resources h2 {
    color: #383b41;
    border-bottom: 2px solid #383b41;
    margin: 25px 0 12px;
    font-size: 20px;
    text-align: left;
    padding-bottom: 5px;
}
#parent-resources .pr-thumb {
    border-radius: 4px;
    border: solid 1px #0e5384;
    background-color: #ffffff;
    width: 31%;
    margin: 0 1% 15px;
    padding: 10px;
    cursor: pointer;
}
#parent-resources .pr-thumb img {
    width: 70px;
    height: auto;
    margin-right: 15px;
    float: left;
}
#parent-resources .pr-thumb .text {
    padding: 3px 0;
    height: 62px;
}
#parent-resources .pr-thumb span {
    color: #000;
    font-size: 14px;
}
#parent-resources .pr-thumb span.title {
    display: block;
    font-weight: 800;
    line-height: 1.36;
    text-align: left;
    color: #000;
    font-size: 16px;
    margin-bottom: 8px;
    outline: none;
}
#parent-resources button.pr-thumb span.title {
    font-weight: 600;
    font-size: 15px;
}
#parent-resources .resource-cat {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 1000px) {
    #parent-resources .pr-thumb {
        width: 48%;
    }
}

@media (max-width: 750px) {
    #parent-resources .pr-thumb img {
        width: 80px;
        height: 88px;
    }
    #parent-resources .pr-thumb .text {
        height: auto;
    }
}

@media (max-width: 560px) {
    #parent-resources .pr-thumb {
        width: 98%;
    }
}

