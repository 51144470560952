.thumbnail-link {
    background-color: #ffffff;
    margin: 0 auto;
    padding: 25px 6px;
    display: block;
    width: 100%;
    position: relative;
    vertical-align: top;
    cursor: pointer;
    border-bottom: 1px solid #155387;
}
.thumbnail-link img {
    width: 120px;
    height: auto;
    float: left;
    margin-right: 16px;
}
.sba .thumbnail-link {
    padding: 25px 6px 25px 50px;
}
.thumbnail-link .title {
    display: block;
    vertical-align: top;
    font-weight: bold;
    line-height: 1.36;
    text-align: left;
    color: #000000;
    margin-bottom: 8px;
    padding-right: 150px;
    font-size: 20px;
    margin-top: 5px;
}
.thumbnail-link .description {
    font-size: 18px;
    line-height: 1.42;
    color: #000000;
    margin-bottom: 5px;
    margin-left: 136px;
}
.thumbnail-link a {
    background-color: #ffffff;
    border-radius: 18px;
    padding: 3px 20px;
    display: inline-block;
    margin-left: -2px;
    font-weight: 800;
}
.sba .thumbnail-link a {
    border: solid 2px #17b7a9;
    color: #176d65;
}
.games .thumbnail-link a {
    border: solid 2px #01375d;
    color: #01375d;
}
.thumbnail-link .grades {
    font-weight: 800;
    font-size: 14px;
    padding: 0;
    display: inline-block;
}

@media (max-width: 1150px) {
    .thumbnail-link {
        width: 96%;
        margin-left: 2%;
    }
    .thumbnail-link:nth-child(2n) {
        margin-left: 2%;
    }
}
@media (max-width: 680px) {
    .sba .thumbnail-link {
        padding: 25px 6px;
    }
}

@media (max-width: 480px) {
    .thumbnail-link {
        padding-bottom: 25px;
    }
    .prep-section h3 {
        text-align: center;
    }

    .thumbnail-link .title {
        text-align: center;
        padding: 0;
    }
    .thumbnail-link img {
        width: 160px;
        display: block;
        height: auto;
        float: none;
        margin: 0 auto 10px;
        max-width: 160px;
    }
    .thumbnail-link .title, .thumbnail-link .description {
        margin-left: 0;
    }
    .thumbnail-link .grades {
        width: 100%;
        top: auto;
        bottom: 0;
        left: 0;
        border-radius: 0 0 5px 5px;
        text-align: center;
    }
    .result-links a {
        width: 100%;
    }
    .prep-section h3 {
        padding-right: 10px;
    }
    .prep-section h3 svg:last-of-type {
        right: -8px;
        top: 0;
    }
}
