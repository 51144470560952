#popup {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    cursor: pointer;
}
#popup .container {
    width: 80%;
    height: 80%;
    position: absolute;
    left: 10%;
    top: 10%;
}
#popup .contents {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
    background: #fff;
    height: 100%;
    border-radius: 8px;
    position: relative;
    cursor: default;
}
#popup .close {
    position: absolute;
    font-size: 30px;
    top: -15px;
    right: -15px;
    background: #fff;
    border-radius: 100px;
    cursor: pointer;
    z-index: 9;
}
#popup .youtube {
    width: 100%;
    height: 100%;
}
#popup iframe {
    width: 100%;
    height: 100%;
}
