#pods-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.8);
    cursor: pointer;
}
#pods-modal .pod-content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -125px 0 0 -280px;
    max-width: 95%;
    width: 560px;
    font-size: 16px;
    height: 250px;
    background: #fff;
    border-radius: 10px;
}
#pods-modal h2 {
    color: #0b3c96;
}
#pods-modal a {
    display: block;
}
#pods-modal a img {
    max-width: 100%;
}

#pods-modal .pod-content .close {
    position: absolute;
    top: -15px;
    right: -15px;
    font-size: 32px;
    background: #fff;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    overflow: hidden;
    cursor: pointer;
}
#pods-modal .close svg {
    display: block;
}
#pods-modal .close span {
    display: block;
    margin: -1px 0 0 -1px;
}
