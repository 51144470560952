#enrichment {
    border: #D7FFFF 5px solid;
    background: #fff;
    font-family: "Open Sans", "Helvetica", "Arial", arial, sans-serif;
    margin: 0;
    padding: 0 0 100px;
    width: 100%;
    position: relative;
    z-index: 3;
}
#enrichment p {
    margin: 0;
}
#enrichment .header {
    background: #D6E7FC;
    min-height: 84px;
    position: relative;
}
#enrichment .header .logo {
    position: absolute;
    margin: -17px 0 0 15px;
}
#enrichment h2 {
    font-family: "Open Sans", "Helvetica", "Arial", arial, sans-serif;
    float: left;
    margin: 0 0 0 80px;
    color: #2faadf;
    font-size: 3.5em;
    line-height: .9em;
}
#enrichment h2 span {
    display: block;
    font-size: .6em;
    color: #52caca;
    font-style: oblique;
    margin-left: 100px;
    line-height: 1em;
}
#enrichment .factoid {
    float: right;
    width: 40%;
    padding-bottom: 8px;
    padding-right: 15px;
    font-size: 14px;
}
#enrichment .factoid .title {
    font-weight: bold;
    letter-spacing: .3em;
    margin: 10px 0 0;
}
#enrichment .item-container {
    overflow: hidden;
    font-size: 14px;
}
#enrichment .item-container ul {
    list-style: outside;
    margin: 1em 1.5em
}
#enrichment #next-week .item-container {
    display: none;
}
#enrichment .week-container {
    padding: 35px 35px 0;
}
#enrichment .week-container ul {
    background-color: #fff;
}
#enrichment .week {
    font-family: "Itim";
}
#enrichment .week p {
    margin: 0;
}
#enrichment .week p:last-child {
    font-size: 2em;
}
#enrichment nav {
    width: 100%;
    position: relative;
}
#enrichment nav ul {
    margin: 10px 0 20px;
    clear: both;
    width: 100%;
    display: flex;
}
#enrichment nav li {
    position: relative;
    list-style: none;
    display: block;
    float: left;
    width: 24%;
    margin-right: 1.3333%;
    color: #2faadf;
    border: 2px solid #2faadf;
    border-radius: 4px;
    text-align: center;
    padding: 10px;
    font-weight: 600;
    cursor: pointer;
}
#enrichment nav li.active {
    color: #fff;
    background: #2faadf;
}
#enrichment nav li.active:after {
    position: absolute;
    content: "";
    display: block;
    bottom: -10px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: #2faadf transparent transparent transparent;
}
#enrichment nav li.active:hover {
    background: #2faadf;
}
#enrichment nav li:hover {
    background: #d1effc;
}

#enrichment nav li[data-grade="4"] {
    margin: 0;
}
#enrichment nav li.clear {
    padding: 0;
    border: none;
}
#enrichment #next-week .item-container {
    padding-bottom: 35px;
}
#enrichment .section {
    width: 48%;
    float: left;
    background: #fafafa;
    padding: 20px;
    min-height: 345px;
}
#enrichment .section.offline {
    float: right;
}
#enrichment .section > p:first-child {
    color: #2e566d;
    font-weight: bold;
    letter-spacing: .3em;
    padding-bottom: 6px;
    border-bottom: 2px solid #e7e7e7;
}
#enrichment .item {
    position: relative;
    margin: 10px 0 20px;
}
#enrichment .section a {
    color: #027bb8;
    width: 100%;
    height: 100%;
}
#enrichment .section a:hover {
    color: #000;
}
#enrichment .section .icon a:hover {
    opacity: .8;
}
#enrichment .icon {
    width: 50px;
    height: 50px;
    float: left;
    margin: 0 15px 0 0;
}
#enrichment .icon img {
    max-width: 100%;
}
#enrichment .text {
    width: calc(100% - 65px);
    float: right;
}
#enrichment .text .title {
    font-weight: bold;
}


/**  SET DIFFERENT STYLES FOR THE VARIOUS SEASONS; ABOVE COLOR SETTINGS ARE FOR WINTER **/
#enrichment.Spring-Enrichment {
    border-color: #fdffd7;
}
#enrichment.Spring-Enrichment .header {
    background: #d9fcd6;
}
#enrichment.Spring-Enrichment .header .right {
    background: url(//cdn.testingmom.com/img/summer-learning/flowers.png);
    height: 100px;
    width: 113px;
    position: absolute;
    right: 10px;
    bottom: 0;
}
#enrichment.Spring-Enrichment h2 {
    text-align: left;
    margin: 0 0 0 110px;
    color: #e47cff;
}
#enrichment.Spring-Enrichment h2 span {
    margin-left: 40px;
    line-height: 1.4em;
    color: #b48af9;
}
#enrichment.Spring-Enrichment nav li {
    border-color: #e47cff;
    color: #b48af9;
}
#enrichment.Spring-Enrichment nav li.active {
    background-color: #e47cff;
    color: #fff;
}
#enrichment.Spring-Enrichment nav li.active:after {
    border-color: #e47cff transparent transparent transparent;
}
@media (max-width: 1150px) {
    #enrichment.Spring-Enrichment .factoid {
        float: none;
        width: 80%;
        margin: 15px auto 0;
    }

    #enrichment.Spring-Enrichment h2 {
        position: relative;
        z-index: 2;
        float: none;
    }
}



@media (max-width: 1050px) {
    #enrichment nav {
        display: block;
    }
}

@media (max-width: 800px) {
    #enrichment h2 {
        position: relative;
        z-index: 2;
        float: none;
        margin: 0;
        text-align: center;
    }
    #enrichment .logo {
        z-index: 1;
    }
    #enrichment .factoid {
        float: none;
        width: 80%;
        margin: 15px auto 0;
    }
    #enrichment .section {
        width: 100%;
        float: none !important;
        padding-bottom: 0;
        margin-bottom: 20px;
    }
    #enrichment .header {
        height: auto;
        padding-bottom: 15px;
    }

    #enrichment.Spring-Enrichment h2 {
        text-align: left;
        margin: 0 0 0 102px;
    }
}
@media (max-width: 600px) {
    #enrichment nav li {
        width: 49%;
        margin-right: 2%;
    }
    #enrichment nav li.active:after {
        border: none;
    }
    #enrichment nav li[data-grade="2"] {
        margin: 0 0 8px;
    }
    #enrichment .icon {
        margin-bottom: 5px;
    }
    #enrichment .text {
        width: 100%;
        float: none;
    }
    #enrichment .week-container {
        padding: 15px 15px 0;
    }
    #enrichment.Spring-Enrichment .header .right {
        display: none;
    }
}
@media (max-width: 500px) {
    #enrichment.Spring-Enrichment h2 span {
        margin-left: 0;
    }
}
@media (max-width: 440px) {
    #enrichment .header .logo {
        display: none;
    }
    #enrichment.Spring-Enrichment h2 {
        margin-left: 10px;
    }
    #enrichment nav li {
        width: 96%;
        margin: 0 auto 8px;
    }
}

/** SETTINGS FOR SUMMER ENRICHMENT **/
#enrichment.Summer-Enrichment {
    border-color: #fdffd7;
}
#enrichment.Summer-Enrichment .header {
    background: #feefa77d;
}
#enrichment.Summer-Enrichment .header .right {
    background: url(//cdn.testingmom.com/img/summer-learning/flowers.png);
    height: 100px;
    width: 113px;
    position: absolute;
    right: 10px;
    bottom: 0;
}
#enrichment.Summer-Enrichment h2 {
    text-align: left;
    margin: 0 0 0 110px;
    color: #EF5B37;
}
#enrichment.Summer-Enrichment h2 span {
    margin-left: 40px;
    line-height: 1.4em;
    color: #ff6c00;
}
#enrichment.Summer-Enrichment nav li {
    border-color: #EF5B37;
    color: #ff6c00;
    background-color: #e5f3fc;
}
#enrichment.Summer-Enrichment nav li.active {
    background-color: #EF5B37;
    color: #fff;
}
#enrichment.Summer-Enrichment nav li.active:after {
    border-color: #EF5B37 transparent transparent transparent;
}
@media (max-width: 1150px) {
    #enrichment.Summer-Enrichment .factoid {
        float: none;
        width: 80%;
        margin: 15px auto 0;
    }

    #enrichment.Summer-Enrichment h2 {
        position: relative;
        z-index: 2;
        float: none;
    }
}



@media (max-width: 1050px) {
    #enrichment nav {
        display: block;
    }
}

@media (max-width: 800px) {
    #enrichment h2 {
        position: relative;
        z-index: 2;
        float: none;
        margin: 0;
        text-align: center;
    }
    #enrichment .logo {
        z-index: 1;
    }
    #enrichment .factoid {
        float: none;
        width: 80%;
        margin: 15px auto 0;
    }
    #enrichment .section {
        width: 100%;
        float: none !important;
        padding-bottom: 0;
        margin-bottom: 20px;
    }
    #enrichment .header {
        height: auto;
        padding-bottom: 15px;
    }

    #enrichment.Summer-Enrichment h2 {
        text-align: left;
        margin: 0 0 0 102px;
    }
}
@media (max-width: 600px) {
    #enrichment nav li {
        width: 49%;
        margin-right: 2%;
    }
    #enrichment nav li.active:after {
        border: none;
    }
    #enrichment nav li[data-grade="2"] {
        margin: 0 0 8px;
    }
    #enrichment .icon {
        margin-bottom: 5px;
    }
    #enrichment .text {
        width: 100%;
        float: none;
    }
    #enrichment .week-container {
        padding: 15px 15px 0;
    }
    #enrichment.Summer-Enrichment .header .right {
        display: none;
    }
}
@media (max-width: 500px) {
    #enrichment.Summer-Enrichment h2 span {
        margin-left: 0;
    }
}
@media (max-width: 440px) {
    #enrichment .header .logo {
        display: none;
    }

    #enrichment.Summer-Enrichment h2 {
        margin-left: 10px;
    }

    #enrichment nav li {
        width: 96%;
        margin: 0 auto 8px;
    }
}

/** SETTINGS FOR FALL ENRICHMENT **/
#enrichment.Fall-Enrichment {
    border-color: #fdffd7;
}
#enrichment.Fall-Enrichment .header {
    background: #FFFAE8;
}
#enrichment.Fall-Enrichment .header .right {
    background: url(//cdn.testingmom.com/img/Fall-learning/flowers.png);
    height: 100px;
    width: 113px;
    position: absolute;
    right: 10px;
    bottom: 0;
}
#enrichment.Fall-Enrichment h2 {
    text-align: left;
    margin: 0 0 0 150px;
    color: #CD1B00;
}
#enrichment.Fall-Enrichment h2 span {
    margin-left: 40px;
    line-height: 1.4em;
    color: #FC6B16;
}
#enrichment.Fall-Enrichment nav li {
    border-color: #CD1B00;
    color: #FC6B16;
}
#enrichment.Fall-Enrichment nav li.active {
    background-color: #CD1B00;
    color: #fff;
}
#enrichment.Fall-Enrichment nav li.active:after {
    border-color: #CD1B00 transparent transparent transparent;
}
@media (max-width: 1150px) {
    #enrichment.Fall-Enrichment .factoid {
        float: none;
        width: 80%;
        margin: 15px auto 0;
    }

    #enrichment.Fall-Enrichment h2 {
        position: relative;
        z-index: 2;
        float: none;
    }
}



@media (max-width: 1050px) {
    #enrichment nav {
        display: block;
    }
}

@media (max-width: 800px) {
    #enrichment h2 {
        position: relative;
        z-index: 2;
        float: none;
        margin: 0;
        text-align: center;
    }
    #enrichment .logo {
        z-index: 1;
    }
    #enrichment .factoid {
        float: none;
        width: 80%;
        margin: 15px auto 0;
    }
    #enrichment .section {
        width: 100%;
        float: none !important;
        padding-bottom: 0;
        margin-bottom: 20px;
    }
    #enrichment .header {
        height: auto;
        padding-bottom: 15px;
    }

    #enrichment.Fall-Enrichment h2 {
        text-align: left;
        margin: 0 0 0 102px;
    }
}
@media (max-width: 600px) {
    #enrichment nav li {
        width: 49%;
        margin-right: 2%;
    }
    #enrichment nav li.active:after {
        border: none;
    }
    #enrichment nav li[data-grade="2"] {
        margin: 0 0 8px;
    }
    #enrichment .icon {
        margin-bottom: 5px;
    }
    #enrichment .text {
        width: 100%;
        float: none;
    }
    #enrichment .week-container {
        padding: 15px 15px 0;
    }
    #enrichment.Fall-Enrichment .header .right {
        display: none;
    }
}
@media (max-width: 500px) {
    #enrichment.Fall-Enrichment h2 span {
        margin-left: 0;
    }
}
@media (max-width: 440px) {
    #enrichment .header .logo {
        display: none;
    }

    #enrichment.Fall-Enrichment h2 {
        margin-left: 10px;
    }

    #enrichment nav li {
        width: 96%;
        margin: 0 auto 8px;
    }
}

/** SETTINGS FOR WINTER ENRICHMENT **/
#enrichment.Winter-Enrichment {
    border-color: #fdffd7;
}
#enrichment.Winter-Enrichment .header {
    background: #D6E7FC;
}
#enrichment.Winter-Enrichment .header .right {
    background: url(//cdn.testingmom.com/img/Winter-learning/flowers.png);
    height: 100px;
    width: 113px;
    position: absolute;
    right: 10px;
    bottom: 0;
}
#enrichment.Winter-Enrichment h2 {
    text-align: left;
    margin: 0 0 0 150px;
    color: #2faadf;
}
#enrichment.Winter-Enrichment h2 span {
    margin-left: 40px;
    line-height: 1.4em;
    color: #52caca;
}
#enrichment.Winter-Enrichment nav li {
    border-color: #2faadf;
    color: #52caca;
}
#enrichment.Winter-Enrichment nav li.active {
    background-color: #2faadf;
    color: #fff;
}
#enrichment.Winter-Enrichment nav li.active:after {
    border-color: #2faadf transparent transparent transparent;
}
@media (max-width: 1150px) {
    #enrichment.Winter-Enrichment .factoid {
        float: none;
        width: 80%;
        margin: 15px auto 0;
    }

    #enrichment.Winter-Enrichment h2 {
        position: relative;
        z-index: 2;
        float: none;
    }
}



@media (max-width: 1050px) {
    #enrichment nav {
        display: block;
    }
}

@media (max-width: 800px) {
    #enrichment h2 {
        position: relative;
        z-index: 2;
        float: none;
        margin: 0;
        text-align: center;
    }
    #enrichment .logo {
        z-index: 1;
    }
    #enrichment .factoid {
        float: none;
        width: 80%;
        margin: 15px auto 0;
    }
    #enrichment .section {
        width: 100%;
        float: none !important;
        padding-bottom: 0;
        margin-bottom: 20px;
    }
    #enrichment .header {
        height: auto;
        padding-bottom: 15px;
    }

    #enrichment.Winter-Enrichment h2 {
        text-align: left;
        margin: 0 0 0 102px;
    }
}
@media (max-width: 600px) {
    #enrichment nav li {
        width: 49%;
        margin-right: 2%;
    }
    #enrichment nav li.active:after {
        border: none;
    }
    #enrichment nav li[data-grade="2"] {
        margin: 0 0 8px;
    }
    #enrichment .icon {
        margin-bottom: 5px;
    }
    #enrichment .text {
        width: 100%;
        float: none;
    }
    #enrichment .week-container {
        padding: 15px 15px 0;
    }
    #enrichment.Winter-Enrichment .header .right {
        display: none;
    }
}
@media (max-width: 500px) {
    #enrichment.Winter-Enrichment h2 span {
        margin-left: 0;
    }
    #enrichment.Summer-Enrichment nav li {
        font-size: 12px;
        padding: 4px;
    }
}
@media (max-width: 440px) {
    #enrichment .header .logo {
        display: none;
    }

    #enrichment.Winter-Enrichment h2 {
        margin-left: 10px;
    }

    #enrichment nav li {
        width: 96%;
        margin: 0 auto 8px;
    }
    #enrichment.Summer-Enrichment h2 {
        font-size: 3em;
    }
    #enrichment .item-container ul {
        margin: 1em 0.5em;
    }
}
