.assessment {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 30px;
    user-select: none;
}
.assessment .assessment {
    background: none;
}
.assessment h1, .assessment h2, .assessment h3 {
    font-size: 22px;
    margin-bottom: 10px;
    padding-bottom: 10px;
}
.assessment .overlay, .assessment .free-practice-overlay {
    z-index: 1000;
    position: fixed;
    background: rgba(50, 50, 50, 0.6);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
.assessment .message-overlay {
    background-color: #fff;
    color: #1d1d1d;
    z-index: 11;
    position: relative;
    width: 60%;
    min-width: 320px;
    max-width: 500px;
    height: auto;
    padding: 15px 25px;
    margin: 250px auto 0;
    box-shadow: 1px 1px 10px #444;
    overflow: auto;
    border-radius: 5px;
}
.assessment .message-overlay p {
    font-size: 16px;
    margin-bottom: 12px;
}
.assessment .message-overlay p:first-of-type {
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 20px;
}
.assessment .message-overlay button {
    background-color: #0e5384;
    color: #fff;
    font-size: 16px;
    margin: 10px auto;
    border-radius: 3px;
    border: none;
    padding: 5px 10px;
    display: block;
}
.assessment .message-overlay.start > p {
    background-color: #00a22b;
    color: #fff;
    font-size: 30px;
    position: relative;
    width: 200px;
    height: 60px;
    padding: 7px 0;
    margin: 20px auto;
    box-shadow: 1px 1px 10px #444;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}
.assessment .start-overlay p {
    vertical-align: middle;
    display: inline-block;
}
.assessment svg.fa-exclamation-triangle {
    color: #f06821;
    font-size: 23.6px;
    margin-right: 12px;
}
.questions {
    width: 80%;
    height: 80%;
    position: absolute;
    left: 10%;
    top: 10%;
}
.questions .close {
    position: absolute;
    font-size: 30px;
    top: -15px;
    right: -15px;
    background: #fff;
    border-radius: 100px;
    cursor: pointer;
    z-index: 2;
}
.questions .container {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
    background: #fff;
    height: 100%;
    border-radius: 8px;
    position: relative;
}
.questions .ques-cont {
    width: 90%;
    background: #fff;
    margin: 0 auto;
    border-radius: 10px;
    padding: 20px;
    overflow: auto;
    display: none;
    height: 100%;
}
.questions .ques-cont.learnosity {
    height: calc(100% - 40px);
}
.assessment .question {
    width: 100%;
    margin: 0 auto;
    position: relative;
    font-weight: bold;
}
.assessment .question .image {
    margin: 0 auto;
}
.questions #ques-0 {
    display: block;
}
.assessment .question .replay {
    position: absolute;
    right: 0;
    top: 0;
    height: 60px;
    width: 60px;
    max-width: 8%;
    z-index: 1;
    cursor: pointer;
}
.assessment .question .replay img {
    max-width: 100%;
}

.assessment .question .answerAreas .wrong-answer {
    position: absolute;
    z-index: 1;
    cursor: pointer;
}
.assessment .question .answerAreas .correct-answer {
    position: absolute;
    z-index: 2;
    cursor: pointer;
}
.assessment .desc {
    color: #CCCCCC;
    font-size: .7em;
    padding: 9px;
    border-top: 1px solid #CCCCCC;
    cursor: default;
}
.assessment .desc:hover .report-error {
	color: black;
}
.assessment .learnosity .desc {
    bottom: 0;
    position: absolute;
}
.assessment .desc .report-error {
    display: inline-block;
}
.assessment .question .image img {
    width: 100%;
    height: 100%;
}
.assessment button.continue {
    color: #fff;
    font-size: 20px;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-bottom: 20px;
    text-shadow: 1px 1px 1px #3c3c3c;
    box-shadow: 1px 1px 3px #4e4e4e;
    background: #04c304;
}

.lrn_widget .lrn-response-validate-wrapper {
    margin: 0 auto;
}
.lrn_stimulus {
    font-size: 18px;
}
#results {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#results .fireworks {
    width: 100%;
    height: 100%;
    margin: 5% auto;
    max-width: 1075px;
    max-height: 524px;
    margin-top: 50px;
}
#results .fireworks img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
#results .spacebaby {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 200px
}
#results .options {
    position: absolute;
    width: 100%;
    font-size: 40px;
    font-weight: 700;
    font-family: "Nunito";
    bottom: 120px;
    color: #fff;
    text-align: center;
}
#results .options p {
    margin-bottom: 30px;
    text-shadow: 1px 1px 5px #000;
}
#results button {
    padding: 8px 12px;
    border-radius: 100px;
    font-size: 24px;
    border: none;
    background: #17b7a9;
    margin: 0 40px;
    color: #fff;
    font-family: "Nunito Sans";
    cursor: pointer;
    outline: none;
}

#results button:last-child {
    background: #03bfd7;
}
.results .question .user-response {
    background: #f03f22;
    border: 1px solid #fff;
    height: 16px;
    width: 16px;
    border-radius: 16px;
    position: absolute;
}
.results .correct-answer {
    background: rgba(118, 179, 46, .5);
}
.free-practice .results .questions .ques-cont {
    height: calc(100% - 62px);
}
.free-practice .results #result-0 {
    display: block;
}
#reportedError {
    display: none;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}
#reportedError .container {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    top: 50%;
    left: 50%;
    margin-top: -175px;
    margin-left: -150px;
    position: absolute;
    height: 350px;
}
#reportedError .close {
    font-size: 30px;
    position: absolute;
    top: -15px;
    right: -15px;
    height: 30px;
}
#reportedError .close svg {
    background-color: #fff;
    border-radius: 100px;
}
#reportedError p {
    margin-bottom: 20px;
}
#reportedError textarea {
    height: 150px;
    width: 100%;
    resize: none;
    margin-bottom: 20px;
}
#reportedError button {
    color: #fff;
    background: #17b7a9;
    border-radius: 6px;
    padding: 10px 20px;
    font-size: 20px;
    font-weight: 400;
    outline: none;
}
#reportedError label {
    display: block;
    margin-top: 10px;
}
#assessment-results #legend {
    float: right;
    color: #000;
}
#assessment-results #legend > div {
    display: inline-block;
}
#assessment-results #legend > div:first-of-type {
    margin-right: 20px;
}
#assessment-results #legend .correct-area {
    background: rgba(118,179,46,.5);
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
}
#assessment-results #legend .your-selection {
    background: #f03f22;
    border: 1px solid #fff;
    height: 16px;
    width: 16px;
    border-radius: 16px;
    display: inline-block;
    vertical-align: middle;
}
.your-response p {
    text-align: center;
    margin: 10px 0;
}
.your-response p svg {
    font-size: 24px;
    vertical-align: middle;
    color: #f06821;
    margin-left: 5px;
}
#questions-results .learnosity-item {
    max-height: 95%;
    overflow: auto;
    max-width: 100%;
}
#assessment .questions h2 {
    color: #fff;
    padding-top: 15%;
}
#assessment .questions h2.green-heading {
    color: #176d65;
}
#assessment .questions .message-overlay h2 {
    padding-top: 0;
}
#testimonial {
    padding: 0 20px;
}
#assessment-results.assessment .question .replay {
    display: none;
}
#assessment p.question-text {
    width: 100%;
}
#assessment .has_audio p.question-text {
    width: calc(100% - 80px);
}

@media(max-width: 1100px) {
    .questions {
        width: 90%;
        height: 90%;
        left: 5%;
        top: 5%;
    }
}

@media(max-width: 950px) {
    .questions .ques-cont {
        padding: 10px;
    }
    .assessment .question .replay {
        top: 0;
        right: -6%;
        width: 40px;
    }
    .free-practice p.question-text {
        padding-right: 5px;
    }
}

@media(max-height: 680px) {
    .free-practice #questions-results .ques-cont {
        height: 70vh;
        overflow: auto;
        padding: 5px 10px;
    }
    .questions {
        width: 90%;
        height: 90%;
        left: 5%;
        top: 5%;
    }
}

@media(max-width: 850px) {
    .questions {
        top: 3%;
    }
}

@media(max-width: 850px) and (max-height: 500px) {
    .free-practice #questions-results.questions {
        width: 92%;
        left: 4%;
    }
    .free-practice #questions-results .answer-image {
        height: auto!important;
        width: 50%!important;
    }
    .your-response p {
        font-size: 16px;
    }
    .your-response p svg {
        font-size: 18px;
        vertical-align: sub;
        margin-left: 0;
    }
    .free-practice #questions-results .ques-cont {
        height: 70vh;
        overflow: auto;
        padding: 5px 10px;
    }
    .questions .close {
        top: -8px;
        right: -18px;
    }
}

@media(max-width: 800px) {
    .assessment .message-overlay {
        margin: 60px auto 0;
    }
}

@media(max-width: 850px) and (min-width: 635px) {
    .questions {
        height: 94%;
    }
}

@media(max-width: 680px) {
    .questions {
        width: 92%;
        left: 4%;
        height: 94%;
    }
    #assessment-results .desc {
        margin-left: -45px;
        width: 117%;
    }
    .free-practice #questions-results .desc {
        margin-left: 0;
        width: 100%;
    }
    #assessment-results #legend > div {
        display: block;
    }
    #assessment-results #legend > div:first-of-type {
        margin-bottom: 1px;
        margin-right: 0;
    }
    #assessment-results #legend {
        background-color: #fff;
    }
}

@media(max-width: 540px) {
    #assessment-results .desc {
        margin-left: 0;
        width: 100%;
        padding: 4px 9px;
        text-align: center;
    }
    #assessment-results #legend {
        float: none;
        text-align: center;
        margin-top: 2px;
    }
    #assessment-results #legend > div {
        display: inline-block;
    }
    #assessment-results #legend > div:first-of-type {
        margin-bottom: 0;
        margin-right: 20px;
    }
    .results .arrow {
        font-size: 20px;
        padding: 2px 6px 0;
        top: 48%;
    }
    .results .arrow {
        left: -10px;
    }
    .results .arrow.right {
        right: -10px;
    }
}

@media (max-height: 500px) and (min-width: 800px) {
    .assessment .message-overlay {
        margin: 60px auto 0;
    }
}
