#test-prep {
    position: relative;
}
#test-prep .control {
    margin: 30px 0 0;
    display: flex;
    justify-content: left;
}
ul.long-list {
    display: flex;
    flex-wrap: wrap;
}
ul.long-list li {
    width: calc(33% - 30px);
    margin-right: 30px;
}
#test-prep .control a {
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-right: none;
    color: #0e5384;
    width: 25%;
    padding: 10px 3px;
    font-size: 18px;
}
#test-prep .control a:hover {
    background: #c8eaff;
}
#test-prep .control a:first-of-type {
    margin-left: 0;
}
#test-prep .control a:last-of-type {
    margin-right: 0;
    border-right: 1px solid #d9d9d9;
}
#test-prep .control a svg {
    width: 100%;
    margin-bottom: 3px;
}
#test-prep .control a.active {
    background-color: #e3f3ff;
    border-bottom: 3px solid #0e5384;
    text-shadow: none;
}
#test-prep .dashboard h1 {
    font-size: 22px;
    line-height: 1.33;
    text-align: center;
    color: #176d65;
    border-bottom: 2px solid #17b7a9;
    padding-bottom: 10px;
}
#test-prep .dashboard h2 {
    font-size: 22px;
    line-height: 1.33;
    text-align: left;
    color: #383b41;
    border-bottom: 2px solid #383b41;
    padding-bottom: 10px;
    margin: 0 0 10px;
}
#test-prep .dashboard h2 svg {
    font-size: 22px;
    margin-right: 4px;
}
#test-prep .dashboard h3 {
    font-size: 24px;
    line-height: 1.33;
    text-align: left;
    color: #383b41;
    border-bottom: 2px solid #383b41;
    padding-bottom: 5px;
    margin: 30px 0 10px;
}
#test-prep .dashboard h3.orange-text {
    color: #f06821;
    border-bottom: 2px solid #f06821;
}
#test-prep .htp-section {
    position: relative;
    margin: 0;
    border: 1px solid #d9d9d9;
    padding: 20px 15px;
}
#test-prep .contact {
    color: #fff;
    background: #992fb9;
    border-radius: 3px;
    text-align: center;
    padding: 10px 5px;
    margin: 20px 0;
}
#test-prep .contact a {
    font-weight: 900;
    display: inline-block;
    font-size: 14px;
    color: #fff;
}
#test-prep .overview p {
    margin-bottom: 14px;
}
#test-prep .overview table {
    margin: 20px 0;
    border: 1px solid #bfbfbf;
    width: 100%;
    text-align: center;
    border-spacing: 0;
    font-size: 16px;
}
#test-prep .overview table tr:nth-child(odd) {
    background: #f4f9f9;
}
#test-prep .overview table th {
    color: #fff;
    padding: 14px 10px;
    background: #03bfd7;
    font-size: 18px;
    line-height: 1.91;
    font-weight: normal;
}
#test-prep .overview table td {
    color: #383b41;
    padding: 20px 0;
    border: 1px solid #bfbfbf;
}
#test-prep ul {
    margin:0 0 10px 20px;
}
#test-prep p {
    margin-bottom: 10px;
}
#test-prep .programs {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
}
#test-prep .fa-star {
    color: #ee5c28;
    font-size: 1em;
    text-shadow: 0px 1px 3px #000;
}
#test-prep .fa-star-request {
    color: #449f4e;
    font-size: 1em;
    text-shadow: 0px 1px 3px #000;
}
#test-prep .thumb {
    width: 14.2%;
    display: inline-block;
    vertical-align: top;
    margin: 0 1% 10px;
    cursor: pointer;
    position: relative;
    text-align: center;
    font-weight: bold;
    color: #0b76b4;
}
#test-prep .thumb img {
    width: 100%;
}
#test-prep .recommended {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0;
}
#test-prep .rec-video {
    width: 31%;
    margin: 0 1%;
    display: flex;
    flex-direction: column;
}
#test-prep .rec-video iframe {
    width: 100%;
    height: 100%;
}
#test-prep .rec-text {
    width: 67%;
    display: flex;
    flex-direction: column;
}
#test-prep .rec-text a {
    font-weight: 700;
    margin-bottom: 10px;
}
#test-prep .rec-text a svg {
    vertical-align: text-bottom;
}
#test-prep .rec-text p {
    margin-bottom: 10px;
}
#test-prep .rec-text p:first-of-type {
    color: #ee4020;
    font-weight: 900;
}
#test-prep .rec-text p:last-of-type {
    font-size: 14px;
}
#test-prep .rec-text p a {
    font-weight: 400;
}
#test-prep .resources .styled-select {
    max-width: 400px;
    width: 100%;
    margin-top: 15px;
}
#test-prep .resource-table h3 {
    margin: 10px 0;
    padding: 0;
    border-bottom: none;
}
#test-prep .resource-table span.table-heading {
    color: #383b41;
    border-bottom: 2px solid #383b41;
    width: 15.5%;
    padding: 0 2.67% 5px;
}
#test-prep h4 {
    font-size: 16px;
    margin: 5px 1%;
}
#test-prep .resource-table table {
    border: none;
    margin-bottom: 0;
}
#test-prep .resource-table th {
    background-color: #fff;
    color: #000;
    border-bottom: 1px solid #bfbfbf;
    padding: 10px 2px 5px;
}
#test-prep .resource-table tr td {
    padding: 10px 0;
    border: none;
}
#test-prep .resource-table tr td:first-child {
    width: 48%;
    text-align: left;
    padding: 10px;
    color: #0e5384;
}
#test-prep .resource-table tr td:nth-child(2) {
    padding: 5px 0;
}
#test-prep .resource-table tr td:nth-child(2), #test-prep .resource-table tr td:nth-child(3),
#test-prep .resource-table tr td:nth-child(4) {
    width: 16%;
    color: #8a8a8a;
}
#test-prep .resource-table svg {
    vertical-align: sub;
    margin-right: 3px;
}
#test-prep .resource-table tr:nth-child(odd) {
    background: #fff;
}
#test-prep .resource-table tr:nth-child(even) {
    background: #f5fbff;
}
#test-prep .lesson-p {
    margin-bottom: 40px;
}
#test-prep .goToAll {
    cursor: pointer;
    text-decoration: underline;
}
#test-prep .lesson .question p {
    white-space: pre-wrap;
}
#test-prep iframe {
     max-width: 100%;
}
#test-prep .line {
    border-bottom: 2px solid #383b41;
    margin: 30px 0 10px;
}
.disclaimer {
    font-size: 12px;
    margin-top: 50px;
}
.disclaimer p {
    margin-bottom: 5px;
}


@media (max-width: 1050px) {
    ul.long-list li {
        width: calc(50% - 30px);
        margin-right: 30px;
    }
    #test-prep .thumb {
        width: 18%;
    }
    #test-prep .thumb .title {
        font-size: 14px;
    }
    #test-prep .rec-video {
        display: block;
        width: 75%;
        margin: 10px auto 25px;
    }
    #test-prep .rec-text {
        width: 100%;
        display: block;
    }
    #test-prep .rec-text a.rec-link {
        display: block;
    }
    #test-prep .resources .left, #test-prep .resources .right {
        float: none;
    }
    #test-prep .goToAll {
        margin-top: 20px;
    }
}

@media (max-width: 800px) {
    #test-prep .control {
        display: block;
    }
    #test-prep .control a {
        width: 100%;
        border-bottom: none;
        margin: 0;
        display: block;
        border-right: 1px solid #d9d9d9;
    }
    #test-prep .control a svg {
        margin-right: 5px;
        margin-bottom: 0;
        width: auto;
    }
    #test-prep .thumb {
        width: 31%;
    }
    #test-prep .resource-table tr td:first-child {
        font-size: 14px;
    }
    #test-prep table th {
        font-size: 14px;
    }
    #test-prep .resource-table h3 {
        font-size: 16px;
    }
    #test-prep .resource-table tr td:nth-child(2), #test-prep .resource-table tr td:nth-child(3),
    #test-prep .resource-table tr td:nth-child(4) {
        font-size: 14px;
    }
}

@media (max-width: 560px) {
    #test-prep .dashboard h2 {
        text-align: center;
    }
    #test-prep .thumb {
        width: 48%;
    }
}
