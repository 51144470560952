.wrapper.get-started {
    margin: 0;
}
#test-dashboard .get-started .dashboard {
    padding: 0 0 30px;
}
.get-started {
    min-height: 900px;
}
.get-started h1 {
    margin-bottom: 0;
    padding-top: 50px;
}
.get-started h2 {
    font-size: 40px;
    font-weight: 300;
    line-height: 1.36;
    color: #1d1d1d;
    text-align: center;
    font-family: "Nunito", Helvetica, Arial, sans-serif;
}
.get-started h3 {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.13;
    text-align: left;
    color: #1d1d1d;
    border-bottom: 2px solid #1d1d1d;
    padding-bottom: 10px;
    font-family: "Nunito", Helvetica, Arial, sans-serif;
    margin-bottom: 15px;
}
.get-started p {
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #1d1d1d;
    margin-bottom: 10px;
}
.get-started .profile-info form {
    width: 75%;
    display: inline-block;
}
.get-started .select-contain {
    width: 22%;
    margin: 0 1% 0 0;
    display: inline-block;
}
.get-started input[type=text] {
    border-radius: 3px;
    border: solid 1px #0e5384;
    background-color: #ffffff;
    font-size: 16px;
    height: 36px;
    padding: 5px 8px;
}
.get-started select {
    font-size: 16px;
    height: 26px;
}
.styled-select svg {
    position: absolute;
    right: 0;
    z-index: 9;
}
.get-started label {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
}
.get-started input[type=text], .get-started .name-input {
    margin-left: 0;
    display: inline-block;
}
.get-started .name-input, .get-started .select-contain {
    width: 100%;
    display: block;
    margin: 0 auto 10px;
}
.get-started input[type=text] {
    width: 100%;
}
#test-dashboard .get-started .practice-btn {
    float: right;
    margin-top: -5px;
    color: #fff;
    padding: 2px 10px;
}
#test-dashboard .get-started .practice-btn:hover {
    background: #fff;
    color: #0e5384;
}
.get-started .testing-info {
    margin: 30px 15px 20px;
    position: relative;
}
.get-started .testing-info .test-box {
    background-color: #fff;
    width: 100%;
    padding: 5px 4px;
    margin-bottom: 12px;
    position: relative;
}
.get-started .testing-info .test-box::-webkit-scrollbar {
    width: 20px;
}
.get-started .testing-info .test-box::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.get-started .testing-info .test-box::-webkit-scrollbar-thumb {
    background: #888;
}
.get-started .testing-info .test-box::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.get-started .testing-info .info-bubble {
    font-size: 16px;
    vertical-align: text-bottom;
    height: 25px;
    color: #0e5384;
    margin-right: 5px;
}
.get-started .testing-info span.heading, .get-started .testing-info p.heading {
    font-size: 22px;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: 700;
    color: #3c3c3c;
}
.get-started .testing-info p.heading.additional {
    margin-top: 10px;
}
.get-started .testing-info .test-box .info-circle {
    color: #0e5384;
}
.get-started .testing-info p.instructions {
    color: #8b8b8b;
}
.get-started .testing-info .arrow-right {
    color: #c7c7c7;
    font-size: 50px;
    width: 4%;
    text-align: center;
    margin: 75px 0;
}
.get-started .testing-info .styled-select {
    width: 44%;
    margin-top: 20px;
}
.get-started .test-box input[type=radio] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.get-started .test-box .option {
    margin: 3px 0 12px;
    clear: both;
}
.get-started .option label {
    cursor: pointer;
}
.get-started .test-box .option span {
    font-size: 16px;
    color: #1d1d1d;
    display: inline-block;
    vertical-align: bottom;

}
.get-started .test-box .option label span:last-of-type {
    margin-left: 10%;
}
.checkmark {
    position: relative;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: solid 1px #0e5384;
    border-radius: 20px;
    margin-left: 7%;
}
.get-started .test-box label input:checked ~ .checkmark {
    background-color: #fff;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.get-started .test-box label input:checked ~ .checkmark:after {
    display: block;
}
.get-started .test-box label .checkmark:after {
    width: 14px;
    height: 14px;
    background-color: #0e5384;
    border: 2px solid #FFF;
    border-radius: 20px;
}
.get-started .btn {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 3px;
    font-size: 18px;
    margin-bottom: 5px;
}

.get-started .grey-btn {
    color: #8b8b8b;
    border: solid 1px #d8d8d8;
    background-color: #e9e9e9;
    line-height: 1.4;
}

.get-started .blue-text {
    color: #0e5384;
    font-weight: 900;
    display: inline-block;
}
.get-started .green-btn {
    background-color: #17b7a9;
    color: #fff;
    border: solid 1px #17b7a9;
    font-weight: 900;
}
#test-dashboard .get-started .btns .buy-now {
    float: none;
    margin-right: 0;
    margin-left: 20px;
    background-color: #e94218;
    font-weight: 900;
    border: 1px solid #e94218;
    color: #fff;
}
#test-dashboard .get-started .btns.noAccess .buy-now {
    padding-top: 0;
    padding-bottom: 0;
    background: none;
    border: none;
    margin-left: 0;
}
.get-started .test-box ul {
    list-style: none;
    margin: 0;
    column-count: 3;
}
.get-started .test-box ul li {
    clear: both;
    height: 32px;
    position: relative;
    display: inline-block;
    width: 100%;
}
.get-started .test-box button {
    background-color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: "Nunito", Helvetica, Arial, sans-serif;
}
.get-started .test-box .checkbox svg {
    margin: 0;
    font-size: 20px;
    color: #0e5384;
    border: none;
    outline: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: sub;
}
.get-started .test-box ul li:nth-child(even) .checkbox, .get-started .test-box ul li:nth-child(even) .primaryBtn {
    background-color: #fff;
}
.get-started .primaryBtn {
    margin: 0 2px 0 10px;
    font-size: 20px;
    color: #17b7a9;
    border: none;
    outline: none;
    cursor: pointer;
}
.get-started .AddTestButton svg {
    display: inline-block;
    font-size: 14px;
    margin-left: 3px;
}
.get-started .test-box ul span {
    font-weight: 500;
    font-size: 18px;
    margin: 3px 0 5px 5px;
}
.get-started .test-box p.break {
    height: 0.5px;
    width: 100%;
    margin: 18px 0 10px;
    background: #c7c7c7;
}
.get-started .AddTestButton {
    float: right;
    color: #a9a9a9;
    background-color: #fff;
    border: solid 1px #a9a9a9;
    border-radius: 3px;
    text-align: center;
    position: relative;
    font-size: 16px;
    width: 70px;
    height: 30px;
}
.get-started .AddTestButton.active {
    color: #0e5384;
    border: solid 1px #0e5384;
    cursor: pointer;
}
.get-started .test-box .option button {
    float: right;
    color: #0e5384;
    padding: 4px 6px;
    background-color: #fff;
    border: solid 1px #0e5384;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    position: relative;
    font-size: 16px;
    width: auto;
    margin-top: -5px;
}
.get-started .test-box .option button svg {
    margin-right: 3px;
    font-size: 16px;
}
.get-started #test-options ul {
    padding: 10px 5px 10px 0;
    border: 1px solid #a9a9a9;
    border-top: none;
    height: 250px;
    position: absolute;
    width: 100%;
    background: #fff;
    margin-bottom: 20px;
    overflow-y: auto;
    display: none;
    z-index: 9;
}
.get-started #test-options li {
    font-size: 16px;
    margin-bottom: 5px;
    padding-bottom: 10px;
    list-style: none;
    margin-left: 5px;
    clear: both;
}
.get-started #test-options li p {
    float: left;
    font-weight: 600;
    font-size: 16px;
    margin-top: 3px;
}
.get-started input[type=text], .get-started input[type=password], .get-started textarea {
    padding: 7px 1% 6px 1%;
}
.get-started #additional-tests {
    width: 44%;
    margin-top: 10px;
    position: relative;
}
.get-started #additional-tests p#tests-dropdown {
    border-radius: 3px;
    border: solid 1px #0e5384;
    background-color: #fff;
    color: #6b6f81;
    padding: 5px 0 5px 5px;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    cursor: pointer;
    margin-left: 0;
    height: 35px;
    font-size: 16px;
    margin-bottom: 0;
}
.get-started #additional-tests:after {
    content: '';
    font-weight: 900;
    position: absolute;
    font-size: 16px;
    border-radius: 0 3px 3px 0;
    top: 15px;
    right: 8.5px;
    color: #0e5384;
    pointer-events: none;
    background-color: #fff;
    width: 0;
    height: 0;
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
    border-top: 7px solid #0e5384;
}

.delete-child {
    cursor: pointer;
    color: #0e5384;
    position: absolute;
    right: 15px;
    top: 73px;
    text-decoration: underline;
    font-size: 16px;
    vertical-align: bottom;
}
.save {
    display: inline-block;
    background: #aaa;
    color: #fff;
    padding: 0 17px;
    font-weight: 700;
    border-radius: 25px;
    cursor: wait;
    line-height: 1.3;
    font-size: 18px;
    margin-top: 10px;
}
.save.active {
    background: #0e5384;
    cursor: pointer;
}
.testing-info .overlay, .btns .overlay {
    position: absolute;
    z-index: 9;
    height: 102%;
    width: 102%;
    background: rgba(200, 200, 200, .5);
    top: -1%;
    left: -1%;
    border: solid 1px #0e5384;
}
.btns {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 15px 20px;
}
.btns.noAccess {
    width: 100%;
    padding: 15px 20px;
}
.btns.noAccess.buy_now {
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btns.noAccess.buy_now.addChild {
    text-align: left;
}
.setUpChild .btn-container {
    max-width: 735px;
    margin: 0 auto;
}
p.changes-saved {
    width: 76%;
    text-align: right;
    font-size: 14px;
    color: #176d65;
    position: absolute;
    visibility: hidden;
}
.get-started #assessment-results {
    display: block;
}
p.correct-total {
    text-align: center;
    font-size: 30px;
    font-weight: 800;
    padding: 10px 0;
    border-bottom: 1px solid #000;
}
#test-dashboard .get-started .results-row:hover {
    background: #fff;
    border: 1px solid #fff;
    border-radius: 0;
    cursor: default;
}
#test-dashboard .get-started .results-row:hover > div:first-child {
    font-weight: normal;
}
#test-dashboard .get-started .results-body {
    max-height: 60vh;
}
.get-started .complete {
    margin-top: 60px;
}
.get-started .complete p {
    text-align: center;
    font-size: 44px;
}
.get-started .finished100 .buy-now {
    bottom: 35px;
    left: calc(50% - 180px);
    width: 360px;
    font-size: 38px;
    background: #e94218;
    color: #fff;
    border: none;
    padding: 15px 40px;
    top: auto;
    right: auto;
    display: block;
    position: absolute;
    text-align: center;
}
.get-started .btn.red-btn {
    background-color: #e94218;
    color: #fff;
    font-weight: 800;
    float: right;
}
.get-started .back-to-dash {
    margin: 15px 0 0 20px;
}
.get-started .blue-btn {
    border: 1px solid #0e5384;
}
.get-started .blue-btn:hover {
    background: #0e5384;
    color: #fff;
}
#test-dashboard .get-started .state-link {
    float: right;
    margin-left: 10px;
    font-size: 15px;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
}
.get-started .profile-info {
    padding: 15px 20px 15px;
    width: 100%;
    max-width: 775px;
    margin: 0 auto;
}
.get-started .profile-info.borderTop {
    border-top: 30px solid #17b7a9;
}
.get-started .profile-info-heading {
    line-height: 1.33;
    text-align: center;
    color: #176d65;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    position: relative;
    border-bottom: none;
}
.get-started .welcome_header {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    display: block;
}
#test-dashboard .get-started .heading-img {
    width: 75px;
    height: 75px;
}
#test-dashboard .get-started .profile-info-subtitle {
    font-style: italic;
    font-size: 16px;
    text-align: center;
}
#test-dashboard .get-started .btns .buy-now img {
    width: 100%;
    max-width: 300px;
}
.get-started .buy_now .flex50:first-of-type {
    text-align: right;
}
.get-started .buy_now .flex50:first-of-type .green-text {
    text-align: right;
    font-weight: 800;
}
.get-started .noAccess .btn-container .btn {
    display: block;
    width: 100%;
    border-radius: 0;
    text-align: center;
    float: right;
    clear: both;
    font-weight: 800;
    padding: 10px 30px;
}
.get-started .btn-container .btn svg {
    margin-right: 5px;
}
.get-started .btn-container .btn.noAccess {
    margin-right: 0;
}
.get-started .change-container {
    width: 100%;
    margin: 0 auto;
}
.get-started .change-section {
    color: #fff;
    border-radius: 3px;
    border: 1px solid #0e5384;
    background-color: #0e5384;
    font-size: 16px;
    padding: 10px;
    vertical-align: bottom;
    cursor: pointer;
    outline: none;
}
.get-started .last {
    left: 15px;
    right: auto;
}
.get-started .last svg {
    font-size: 14px;
}
.get-started .heading-row {
    height: 36px;
    margin-top: 20px;
}
.get-started .btn.marginR {
    margin-right: 5px;
}
.noSetUp {
    color: #ff0000;
    vertical-align: super;
    margin-top: 10px;
    font-weight: 800;
    display: block;
}
.get-started .spacebaby {
    position: absolute;
    bottom: 0;
    width: 300px;
    left: calc(50% - 150px);
}
.legend {
    margin-bottom: 10px;
}
.star-legend svg {
    margin-right: 5px;
    font-size: 20px;
    color: #17b7a9;
    vertical-align: top;
}
.star-legend {
    display: inline-block;
}
.star-legend:first-of-type {
    margin-right: 20px;
}
#test-dashboard .get-started .profile-info .practice-btn {
    margin-top: 0;
    margin-bottom: 0;
    padding: 6px 10px;
}

@media (max-width: 1100px) {
    .get-started {
        position: relative;
    }
    .delete-child {
        top: 5px;
    }
}

@media (max-width: 1050px) {
    .save {
        margin-top: 10px;
    }
    .get-started .practice-btn {
        float: none;
        margin-top: 10px;
        margin-left: 15px;
        display: inline-block;
    }
    .get-started .testing-info .test-box {
        width: 100%;
        float: none;
    }
    .get-started .testing-info .arrow-right {
        margin: 10px auto;
        display: block;
        transform: rotate(90deg);
        width: 75px;
    }
    .get-started .test-box .option label span:last-of-type {
        margin-left: 12%;
    }
    .get-started .AddTestButton svg {
        transform: rotate(90deg);
    }
    .get-started .testing-info .info-bubble {
        display: none;
    }
    .get-started .test-box ul {
        column-count: 2;
    }
}

@media (max-width: 950px) {
    .get-started .profile-info form {
        width: 100%;
    }
    p.correct-total {
        text-align: left;
        padding: 10px 20px;
    }
    #test-dashboard .get-started .dashboard {
        padding-bottom: 50px;
    }
    .get-started  p.question-text {
        font-size: 14px;
        line-height: 1.2;
    }
}

@media (max-width: 850px) {
    .get-started .test-box ul {
        column-count: 1;
    }
}

@media (max-width: 800px) {
    .get-started .spacebaby {
        width: 300px;
        left: calc(50% - 150px);
    }
}

@media (max-width: 720px) {
    .btns.noAccess.buy_now {
        display: block;
    }
    .get-started .noAccess .btn-container .btn {
        float: none;
        width: auto;
        max-width: 300px;
        margin: 10px auto;
    }
    .get-started .buy_now .flex50:first-of-type .green-text {
        text-align: center;
    }
    #test-dashboard .btns .buy-now {
        float: none;
        margin: 0 auto;
        display: block;
    }
    .get-started .noAccess .btn-container {
        display: block;
        margin: 0 auto;
    }
    .get-started .noAccess .btn-container .btn {
        float: none;
    }
    p.correct-total {
        font-size: 22px;
        padding: 5px 12px;
    }
    .get-started .testing-info {
        margin-top: 40px;
    }
    #test-dashboard .get-started .practice-btn {
        clear: both;
    }
    .get-started .btn-container .btn-marginR:first-of-type {
        margin-right: 5px;
    }
}

@media (max-width: 650px) {
    .get-started .change-section {
        width: 100%;
        font-size: 18px;
    }
    .noSetUp {
        margin-left: 0;
    }
    .get-started .change-container {
        text-align: center;
        font-size: 18px;
    }
    #test-dashboard .get-started .profile-info .practice-btn {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
        padding: 6px 10px;
        text-align: center;
    }
}

@media (max-width: 600px) {
    .get-started .testing-info p.instructions {
        font-size: 12px;
    }
    .get-started .testing-info .styled-select {
        width: 100%;
    }
    .get-started a.btn {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }
    .get-started .red-text {
        text-align: center;
        float: none;
        display: block;
    }
    .get-started .green-btn {
        float: none;
        display: block;
        margin: 10px auto;
        text-align: center;
    }
    #test-dashboard .get-started .green-btn.buy-now {
        margin-left: 0;
    }
    .get-started .grey-btn {
        width: 100%;
        text-align: center;
    }
    .btns.noAccess {
        width: 100%;
    }
    .btns.noAccess.buy_now.addChild {
        text-align: center;
    }
    #test-dashboard .get-started .btns .buy-now {
        margin-left: 0;
    }
    .get-started .testing-info {
        margin: 30px 10px 0;
    }
    .get-started .btn-container .btn-marginR:first-of-type {
        margin-bottom: 10px;
    }
    .get-started .heading-row {
        height: auto;
    }
    .get-started .btn.marginR {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width: 500px) {
    #test-dashboard .get-started .dashboard h2 {
        padding-bottom: 20px;
    }
    #test-dashboard .get-started .state-link {
        float: none;
        text-align: center;
        margin: 0 auto 20px;
        display: block;
    }
    .get-started .testing-info {
        margin-top: 10px;
    }
    .delete-child {
        position: relative;
        margin: 0 auto;
        left: auto;
        right: auto;
        display: block;
        text-align: center;
    }
    .get-started .last {
        margin-bottom: 10px;
        left: auto;
    }
    .get-started h1 {
        padding-top: 30px;
    }
}

@media (max-width: 450px) {
    .get-started .select-contain, .get-started .select-contain:nth-of-type(3) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }
    .get-started .col50 {
        margin-left: 0;
    }

    .get-started .testing-info .test-box {
        width: 100%;
    }
    .get-started .testing-info p.instructions {
        font-size: 14px;
    }
    .get-started .testing-info .arrow-right {
        font-size: 40px;
        margin: 8px 0;
        width: 100%;
        transform: rotate(90deg);
    }
    .get-started .testing-info .right.test-box span.heading:nth-of-type(2) {
        width: 67%;
        margin-left: 11%;
    }
    .get-started .testing-info .right.test-box span.heading:first-of-type {
        width: 13%;
    }
    .get-started .test-box .option span {
        font-size: 12px;
    }
    .get-started .test-box .option button {
        font-size: 12px;
        font-weight: bold;
        margin-top: -1px;
    }
    .get-started .test-box .option button svg {
        width: 10px;
        height: 13px;
    }
    .get-started .AddTestButton {
        font-size: 15px;
        width: 58px;
        height: 22px;
    }
    .get-started .AddTestButton svg {
        font-size: 12px;
    }
    .checkmark {
        height: 15px;
        width: 15px;
        margin-left: 4%;
    }
    .get-started .test-box label .checkmark:after {
        width: 14px;
        height: 13px;
        background-color: #0e5384;
        border: none;
        border-radius: 20px;
    }
    .get-started #additional-tests {
        width: 100%;
    }
    .get-started .practice-btn, .save.active {
        margin-top: 0;
    }
    #test-dashboard .get-started .dashboard {
        padding: 0 10px 70px;
    }
    #test-dashboard .get-started .btns .buy-now {
        padding-right: 0;
        padding-left: 0;
    }
    .get-started .practice-btn, .save {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 10px;
        margin-right: 0;
        margin-left: 0;
    }
    .get-started .profile-info {
        padding: 15px 10px;
    }
}

@media (max-width: 380px) {
    .get-started .test-box .option label span:last-of-type {
        margin-left: 1%;
    }
    .get-started .test-box .option button svg {
        display: none;
    }
    .get-started .test-box .option button {
        font-size: 10px;
    }
    .get-started .test-box label .checkmark:after {
        width: 12px;
        height: 10px;
    }
    .checkmark {
        height: 12px;
        width: 12px;
        margin-left: 1%;
    }
    .get-started .test-box .option span {
        vertical-align: middle;
    }
    .get-started .spacebaby {
        width: 250px;
        left: calc(50% - 125px);
    }
}
