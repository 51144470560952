#parent-dashboard  .dashboard h2 {
    font-size: 22px;
    line-height: 1.33;
    font-weight: 700;
    font-family: 'Nunito';
    margin: 0;
    color: #176d65;
}
#parent-dashboard .page-header {
    margin-bottom: 15px;
}
#parent-dashboard .headline {
    position: relative;
    color: #176d65;
    padding-bottom: 5px;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 30px;
    border-top: 55.5px solid #17b7a9;
}
#parent-dashboard.noOnlineClasses .headline {
    margin-top: 0;
}
#parent-dashboard .headline.no-border {
    border: none;
    padding-top: 0;
}
#parent-dashboard .headline a {
    position: absolute;
    top: 0;
    right: 10px;
    color: #0e5384;
    margin-top: 8px;
    font-size: 16px;
}
#parent-dashboard .dashboard .parent-plan {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
}
#parent-dashboard .dashboard .plan-box {
    border: 1px solid #f06821;
    width: 48%;
    margin: 10px 1%;
    flex-direction: column;
    position: relative;
}
#parent-dashboard .dashboard .plan-box:nth-of-type(3n + 2) {
    border-color: #2bb1c8;
}
#parent-dashboard .dashboard .plan-box:nth-of-type(3n) {
    border-color: #00a22b;
}
#parent-dashboard .dashboard .plan-box .top {
    background: #f06821;
    padding: 12px 6px;
}
#parent-dashboard .dashboard .plan-box:nth-of-type(3n + 2) .top {
    background: #2bb1c8;
}
#parent-dashboard .dashboard .plan-box:nth-of-type(3n) .top {
    background: #00a22b;
}
#parent-dashboard .dashboard .plan-box .details {
}
#parent-dashboard .dashboard .plan-box .top > a {
    color: #fff;
    float: right;
    font-size: 16px;
}
#parent-dashboard .dashboard .plan-box h3 {
    font-size: 20px;
    font-weight: 700;
    float: left;
    color: #fff;
    margin: 0;
}
#parent-dashboard .dashboard .plan-box .details {
    padding: 12px;
    clear: both;
    margin-bottom: 52px;
}

#parent-dashboard .dashboard .plan-box .details img {
    width: 25%;
    float: left;
}
#parent-dashboard .dashboard .plan-box .details > a:last-child {
    color: #fff;
    border: 1px solid #f06821;
    background: #f06821;
    border-radius: 3px;
    font-weight: 700;
    font-size: 16px;
    padding: 8px 0;
    text-align: center;
    display: block;
    width: 95%;
    width: calc(100% - 24px);
    position: absolute;
    bottom: 10px;
}
#parent-dashboard .dashboard .plan-box .details > a:last-child:hover {
    color: #f06821;
    background: #fff;
}
#parent-dashboard .dashboard .plan-box:nth-of-type(3n) .details > a:last-child {
    border-color: #00a22b;
    background: #00a22b;
}
#parent-dashboard .dashboard .plan-box:nth-of-type(3n) .details > a:last-child:hover {
    color: #00a22b;
    background: #fff;
}
#parent-dashboard .dashboard .plan-box:nth-of-type(3n + 2) .details > a:last-child {
    background: #2bb1c8;;
    border-color: #2bb1c8;
}
#parent-dashboard .dashboard .plan-box:nth-of-type(3n + 2) .details > a:last-child:hover {
    color: #2bb1c8;
    background: #fff;
}
#parent-dashboard .dashboard .additional-content h3 {
    font-size: 24px;
}
#parent-dashboard .parent-sidebar {
    background: #f5fbff;
    height: auto;
}
#parent-dashboard .parent-sidebar .sidebar-section {
    margin-bottom: 40px;
}
#parent-dashboard .parent-sidebar h3 {
    font-size: 18px;
}
#parent-dashboard .parent-sidebar h3 svg {
    vertical-align: bottom;
}
#parent-dashboard .parent-sidebar .sidebar-section:first-child h3 {
    color: #176d65;
    border-bottom: 1px solid #17b7a9;
}
#parent-dashboard .parent-sidebar .sidebar-section:nth-child(2) h3 {
    color: #ee4020;
    border-bottom: 1px solid #ee4020;
}
#parent-dashboard .parent-sidebar p {
    color: #000;
    margin-top: 15px;
}
#parent-dashboard .parent-sidebar a {
    color: #0e5384;
    font-weight: 700;
    display: block;
    margin-top: 15px;
}
#parent-dashboard .parent-sidebar a:hover {
    text-decoration: underline;
}

#parent-dashboard .scores {
    width: 40%;
    float: left;
    font-size: 14px;
}
#parent-dashboard .individual {
    margin-bottom: 10px;
}
#parent-dashboard .individual:last-of-type {
    margin-bottom: 0;
}
#parent-dashboard .chart {
    width: 60%;
    float: left;
}
#parent-dashboard .details > p {
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
}
#parent-dashboard .details .activity {
    margin-left: 10px;
    color: #0e5384;
}
#parent-dashboard .details .activity strong {
    color: #000;
}
#parent-dashboard .details .noassess {
    margin-bottom: 30px;
    text-align: center;
}
#parent-dashboard .details .noassess a {
    font-weight: bold;
    border: 1px solid #0e5384;
    color: #0e5384;
    padding: 4px 10px;
    border-radius: 3px;
    margin: 10px 0;
    display: inline-block;
}
#parent-dashboard .details .noassess a:hover {
    color: #fff;
    background: #0e5384;
}
#parent-dashboard .details .loggedout .buy-now {
    width: 320px;
    max-width: 100%;
    position: relative;
    margin: 10px auto;
}
#parent-dashboard .details .loggedout .buy-now a {
    color: #fff;
    font-size: 0;
    background: url('/img/homepage/dash-buy-now1.jpg');
    display: block;
    width: 100%;
    padding-bottom: 28.125%;
    background-size: 100%;
}
#parent-dashboard .plan-box:nth-child(2n) .loggedout .buy-now a {
    background: url('/img/homepage/dash-buy-now2.jpg');
}
#parent-dashboard .details .loggedout a:hover {
    opacity: 0.8;
}
.class-list ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
}

.class-list .right {
    margin-left: 10px;
}
.class-list li {
    width: 24%;
    font-size: 15px;
    border-radius: 5px;
    box-shadow: 2px 2px 4px #555;
    overflow: hidden;
    padding-bottom: 10px;
    margin: 0 0.5% 20px;
}
.class-list li .title {
    padding: 0 10px;
}
#parent-dashboard .class-list li {
    width: 48%;
}
#parent-dashboard .class-list.user-classes li {
    width: 98%;
}
.main .class-list p {
    margin-bottom: 0;
    padding: 0 10px;
}
.class-list a {
    display: block;
    color: #0b5182;
}
.online-classes .see-all {
    text-align: center;
    font-size: 18px;
    margin-bottom: 0;
}
.online-classes .class-list div.title {
    min-height: 78px;
    padding: 0 10px;
    margin: 0;
}
#test-dashboard .online-classes {
    text-align: center;
}
#test-dashboard .dashboard .class-list p a {
    text-align: left;
    font-weight: normal;
}
.online-classes img {
    width: 101%;
    max-height: 100%;
}
.online-classes h3 {
    color: #0e5384;
    text-align: center;
    margin: 0 auto;
}
#parent-dashboard .online-classes h3 {
     max-width: 80%;
}
.online-classes > a {
    text-align: center;
}
#parent-dashboard .dashboard {
    padding: 0;
}
.online-classes .thumbnail {
    cursor: pointer;
    position: relative;
    text-align: left;
    padding-bottom: 57.5%;
    margin-bottom: 10px;
    overflow: hidden;
}
.online-classes .thumbnail a {
    display: block;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.tutoring .hp-box-content {
    display: block;
    margin: 10px auto 0;
    width: 90%;
    max-width: 400px;
}
.tutoring h3 {
    color: #0e5384;
}
.tutoring a:hover, #parent-dashboard .online-classes a:hover {
    opacity: 0.7;
}
.tutoring-link {
    text-align: center;
    display: block;
    font-size: 18px;
}
#parent-dashboard .tutoring .headline {
    margin-bottom: 5%;
}
#parent-dashboard.noOnlineClasses .tutoring {
    width: 98%;
}
#parent-dashboard.noOnlineClasses .tutoring .hp-box-content, #parent-dashboard.noOnlineClasses .tutoring a img {
    max-width: 600px;
}
#parent-dashboard.noOnlineClasses .tutoring .headline {
    margin-top: 30px;
    margin-bottom: 15px;
}
.class-list .arrow, .class-list .classes {
    display: inline-block;
    vertical-align: middle;
}
.class-list .classes {
    width: 86%;
    margin: 10px auto 40px;
}
.class-list .arrow {
    color: #0b5182;
    font-size: 30px;
    width: 7%;
    text-align: center;
    cursor: pointer;
}

.class-list li:nth-child(n + 5) {
    display: none;
}
#parent-dashboard .class-list li:nth-child(n + 3) {
    display: none;
}
#parent-dashboard .class-list.user-classes li:nth-child(n + 2) {
    display: none;
}
.site-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.noOnlineClasses.noStudents .site-content {
    border-top: none;
}
.content-category {
    background: #fff;
    display: block;
    border-radius: 5px;
    position: relative;
    margin: 10px 1%;
    padding-bottom: 14px;
    border: 1px solid #2bb6c8;
    width: 48%;
    flex-direction: column;
    cursor: pointer;
}

.content-category.site-tour .pd-content p.description,
.content-category.add-student .pd-content p.description {
    min-height: 45px;
}
.content-category.add-student {
    border-color: #6b579e;
}
.content-category.test-prep {
    border-color: #f4772e;
}
.content-category.sba {
    border-color: #68c65d;
}
.content-category.games {
    border-color: #df68a2;
}
.content-category.parent-resources {
    border-color: #ffc81a;
}
.content-category.online-classes {
    border-color: #0d5282;
}
.content-category.tutoring {
    border-color: #f14344;
}
.pd-header {
    width: 100%;
    background-color: #fff;
    padding: 10px;
}
.content-category.site-tour .pd-header {
    background-color: #23a9b8;
}
.content-category.add-student .pd-header {
    background-color: #6b579e;
}
.content-category.test-prep .pd-header {
    background-color: #eb6426;
}
.content-category.sba .pd-header {
    background-color: #56b04a;
}
.content-category.games .pd-header {
    background-color: #cf6196;
}
.content-category.parent-resources .pd-header {
    background-color: #ebc000;
}
.content-category.online-classes .pd-header {
    background-color: #0d5282;
}
.content-category.tutoring .pd-header {
    background-color: #f14344;
}

.pd-header p {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
}
.pd-content {
    padding: 10px;
}
.pd-thumbnail {
    background-image: url(/img/menu-icon-sprite.png);
    background-size: 100%;
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}
.content-category.site-tour .pd-thumbnail {
    background-position: 0 -40px;
}
.content-category.add-student .pd-thumbnail {
    background: none;
}
.content-category.test-prep .pd-thumbnail {
    background-position: 0 -80px;
}
.content-category.sba .pd-thumbnail {
    background-position: 0 -120px;
}
.content-category.games .pd-thumbnail {
    background-position: 0 -360px;
}
.content-category.parent-resources .pd-thumbnail {
    background-position: 0 -160px;
}
.content-category.online-classes .pd-thumbnail {
    background-position: 0 -520px;
}
.pd-thumbnail svg {
    font-size: 30px;
    color: #fff;
    margin-top: 5px;
    margin-left: 5px;
}
.pd-content {
    text-align: center;
}
.pd-content > p {
    margin: 0 auto 15px;
    min-height: 65px;
    max-width: 550px;
    width: 95%;
}
.pd-content a {
    text-align: center;
    color: #fff;
    border: 1px solid #23a9b8;
    padding: 5px 10px;
    font-weight: 800;
    border-radius: 3px;
    display: block;
    width: 95%;
    margin: 0 auto;
    font-size: 20px;
}
#parent-dashboard .pd-content a:hover {
    background-color: #fff;
}
.content-category.site-tour a, .content-category.site-tour .pd-content img.pd-img {
    border-color: #23a9b8;
}
.content-category.add-student a, .content-category.add-student .pd-content img.pd-img {
    border-color: #6b579e;
}
.content-category.test-prep a, .content-category.test-prep .pd-content img.pd-img {
    border-color: #eb6426;
}
.content-category.sba a, .content-category.sba .pd-content img.pd-img {
    border-color: #56b04a;
}
.content-category.games a, .content-category.games .pd-content img.pd-img {
    border-color: #cf6196;
}
.content-category.parent-resources a, .content-category.parent-resources .pd-content img.pd-img {
    border-color: #ebc000;
}
.content-category.tutoring a, .content-category.tutoring .pd-content img.pd-img {
    border-color: #f14344;
}
.content-category.tutoring .pd-content img.pd-img {
    max-width: 100%;
}
.content-category.site-tour a {
    background-color: #23a9b8;
}
.content-category.add-student a {
    background-color: #6b579e;
}
.content-category.test-prep a {
    background-color: #eb6426;
}
.content-category.sba a {
    background-color: #56b04a;
}
.content-category.games a {
    background-color: #cf6196;
}
.content-category.parent-resources a {
    background-color: #ebc000;
}
.content-category.online-classes .pd-content > div > a {
    position: absolute;
    bottom: 24px;
    width: 92%;
    left: 4%;
}
.recent-activity > a {
    margin-left: 10px;
}
.content-category.online-classes a {
    background-color: #0d5282;
}
.content-category.site-tour a:hover {
    color: #23a9b8;
}
.content-category.add-student a:hover {
    color: #0e5384;
}
.content-category.test-prep a:hover {
    color: #eb6426;
}
.content-category.sba a:hover {
    color: #56b04a;
}
.content-category.games a:hover {
    color: #cf6196;
}
.content-category.parent-resources a:hover {
    color: #ebc000;
}
.content-category.tutoring .pd-content > a:hover {
    color: #f14344;
}
.content-category.online-classes a:hover {
    color: #0d5282;
}
.content-category.online-classes .class-list a:hover, .content-category.online-classes .class-list a {
    border: none;
    background: none;
    color: #0d5282;
}
.content-category.online-classes .class-list a {
    text-align: left;
    font-size: 16px;
    display: block;
    font-weight: normal;
    height: auto;
}
.spacebabies {
    margin: 15px auto 0;
    display: block;
    width: 400px;
}
.pd-content img.pd-img {
    width: 95%;
    border: 1px solid #23a9b8;
    border-radius: 5px;
    margin-bottom: 10px;
    max-width: 550px;
}
.pd-content .classes a p {
    margin: 0;
    min-height: auto;
    max-width: none;
    width: 100%;
}
.pd-content .classes .title div {
    min-height: 70px;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.pd-content .classes .title > div > div {
    flex-basis: 100%;
    height: auto;
    flex: 1 1;
    width: 100%;
    flex-wrap: wrap;
}
.pd-content .classes .title div a {
    text-align: center;
    font-weight: bold;
}
@media (min-width: 1550px) {
    #parent-dashboard .tutoring .headline {
        margin-bottom: 15px;
    }
}

@media (max-width: 1300px) {
    #parent-dashboard .class-list li:nth-child(n + 3) {
        display: none;
    }

    #parent-dashboard .class-list li {
        width: 47%;
    }

    .content-category.online-classes .pd-content > a {
        position: relative;
        left: auto;
        bottom: auto;
    }
    .class-list .classes {
        margin: 10px auto;
    }
}
@media (max-width: 1300px) and (min-width: 900px) {
    #parent-dashboard .class-list.user-classes li {
        flex-basis: 48%;
    }
    #parent-dashboard .class-list.user-classes li:nth-child(n + 2) {
        display: block;
    }
    #parent-dashboard .class-list.user-classes li:nth-child(n + 3) {
        display: none;
    }
}
@media (max-width: 1050px) {
    #parent-dashboard .dashboard .plan-box {
        width: 96%;
        margin: 10px 2%;
    }
    .class-list li {
        width: 49%;
    }
    .class-list li:nth-child(n + 3) {
        display: none;
    }
    .online-classes .arrow {
        font-size: 24px;
        width: 7%;
    }
    .online-classes .classes {
        width: 86%;
    }
}

@media (max-width: 960px) {
    .content-category.test-prep .pd-content p.description,
    .content-category.sba .pd-content p.description,
    .content-category.games .pd-content p.description,
    .content-category.parent-resources .pd-content p.description {
        min-height: 86px;
    }
}
@media (max-width: 950px) {
    #parent-dashboard .tutoring .headline {
        margin-bottom: 8%;
    }
}

@media (max-width: 850px) {
    .content-category.site-tour .pd-content p.description,
    .content-category.add-student .pd-content p.description {
        min-height: 65px;
    }
}

@media (max-width: 800px) {
    .content-category {
        width: 98%;
    }
    #parent-dashboard .pd-content p.description {
        margin-bottom: 25px;
        min-height: 0;
    }
    .content-category.add-student {
        margin-top: 10px;
    }
}

@media (max-width: 680px) {
    #parent-dashboard .scores {
        width: 100%;
        float: none;
        margin-bottom: 15px;
    }
    #parent-dashboard .chart {
        width: 100%;
        float: none;
    }
    #parent-dashboard .individual {
        text-align: center;
    }
    #parent-dashboard .headline a {
        display: block;
        text-align: right;
        position: relative;
    }
    #parent-dashboard .tutoring .headline {
        margin-bottom: 15px;
    }
    #parent-dashboard .class-list li {
        width: 86%;
    }
}
@media (max-width: 480px) {
    .class-list li {
        width: 100%;
        display: block !important;
    }
    .online-classes .classes {
        width: 90%;
        display: block;
    }
    .online-classes .arrow {
        display: none !important;
    }
    .spacebabies {
        width: 280px;
    }
    #parent-dashboard .class-list li {
        width: 98%;
    }
}
@media (max-width: 450px) {
    #parent-dashboard .headline a {
        position: relative;
    }
}
