#test-dashboard {
    margin: 0;
}
#test-dashboard .dashboard {
    padding: 0 0 30px;
}
#test-dashboard .dashboard h2, #test-dashboard .prep-section .prep-heading h3 {
    line-height: 1.33;
    text-align: center;
    color: #176d65;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    position: relative;
}
#test-dashboard .dashboard p a {
    font-weight: bold;
    color: #01375d;
}
#test-dashboard .dashboard .section {
    border-top: 30px solid #17b7a9;
    padding: 15px 30px;
    position: relative;
}
#test-dashboard .prep-section h3 {
    margin-bottom: 0;
    font-size: 1.4em;
}
#test-dashboard .dashboard .section:first-of-type {
    border-top: none;
    position: relative;
}
#test-dashboard .dashboard .section.skill-specific {
    border-top: none;
}
#test-dashboard .dashboard .section.skill-specific h2 {
    border-bottom: 2px solid #155387;
    padding-bottom: 5px;
    width: 80%;
    margin: 0 auto 15px;
}
#test-dashboard .green-text {
    color: #176d65;
    font-weight: bold;
    font-size: 16px;
}
#test-dashboard .recent-assessment {
    text-align: center;
}
#test-dashboard .recent-assessment , #test-dashboard .test-date {
    margin-bottom: 8px;
}
#test-dashboard .recent-assessment p.bold {
    text-align: center;
}
#test-dashboard .recent-assessment span, #test-dashboard .test-date span {
    margin: 0 5px;
}

#test-dashboard .recent-assessment span:first-child {
    margin-left: 0;
}
#test-dashboard .test-date {
    text-align: right;
}
#test-dashboard .results {
    border: 1px solid #d9d9d9;
    border-right: none;
    border-left: none;
    width: 100%;
    user-select: none;
}
#test-dashboard .results-head {
    border-bottom: 1px solid #d9d9d9;
    padding: 10px 22px 10px 5px;
}
#test-dashboard .results-body {
    padding: 10px 5px;
    max-height: 270px;
    overflow: auto;
}
#test-dashboard .results-body::-webkit-scrollbar {
    width: 10px;
}
#test-dashboard .results-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}
#test-dashboard .results-body::-webkit-scrollbar-thumb {
    background: #888;
}
#test-dashboard .results-body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#test-dashboard .results-head > div,
#test-dashboard .results-row > div
{
    display: inline-block;
    vertical-align: middle;
}
#test-dashboard .results-head > div:nth-child(1),
#test-dashboard .results-row > div:nth-child(1)
{
    width: 30%;
    padding-left: 10px;
}
#test-dashboard .results-head > div:nth-child(2),
#test-dashboard .results-row > div:nth-child(2)
{
    width: 60%;
    text-align: left;
}
#test-dashboard .results-head > div:nth-child(3),
#test-dashboard .results-row > div:nth-child(3)
{
    width: 10%;
    text-align: center;
}
#test-dashboard .results-row {
    padding: 10px 0;
    border: 1px solid #fff;
}
#test-dashboard .results-row:hover {
    background: rgba(3, 191, 215, 0.1);
    border: 1px solid rgb(3, 191, 215);
    border-radius: 50px;
    cursor: pointer;
}
#test-dashboard .results-row:hover > div:first-child {
    font-weight: bold;
}
#test-dashboard .results-row > div:nth-child(3) {
    color: #0e5384;
}
#test-dashboard .results-row > div:nth-child(3) svg {
    margin-left: 5px;
}
#test-dashboard .results-row .bar {
    background: #17b7a9;
    height: 24px;
    padding: 2px;
    text-align: right;
}
#test-dashboard .results-row .bar svg {
    color: #ffe224;
    margin-right: 3px;
}
#test-dashboard .results-row button {
    border: 1px solid #0e5384;
    border-radius: 25px;
    color: #0e5384;
    padding: 5px 8px;
    font-size: 16px;
    font-weight: 600;
    outline: none;
    cursor: pointer;
}
#test-dashboard .results-row button svg {
    margin-right: 5px;
    font-size: 14px;
}

#test-dashboard .results-row button.orange {
    background: #f06821;
    border-color: #f06821;
    color: #fff;
}

#test-dashboard .results-row .bar.orange {
    background: #f06821;
}
#test-dashboard .results-row .bar.blue {
    background: #03bfd7;
}
#test-dashboard .results-row .squares > div {
    display: inline-block;
    vertical-align: top;
}
#test-dashboard .results-row .square {
    height: 35px;
    width: 40px;
    background-color: #eaeaea;
    margin-right: 10px;
    margin-bottom: 10px;
    vertical-align: middle;
}
#test-dashboard .results-row .square.correct {
    background-color: #6b569f;
}
#test-dashboard .results-row .square.correct.yellow {
    background-color: #f8d022;
}
#test-dashboard .results-row .trophy {
    color: #f8d022;
    font-size: 36px;
    position: relative;
}
#test-dashboard .results-row .trophy svg {
    position: absolute;
    top: 0;
}

#test-dashboard .results-row .bar span {
    color: #000;
}
#test-dashboard .result-links a {
    color: #0e5384;
    padding: 10px;
    background-color: #fff;
    border: solid 1px #0e5384;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    position: relative;
    font-size: 20px;
    margin: 0 0 10px;
    display: block;
    font-weight: 700;
}
#test-dashboard .result-links a:first-of-type {

}
#test-dashboard .result-links a:hover {
    color: #fff;
    background-color: #0e5384;
}
#test-dashboard .result-links {
    text-align: center;
    margin-top: 20px;
    padding: 0 50px;
}
#test-dashboard .result-links svg {
    margin-right: 8px;
    vertical-align: middle;
}
#test-dashboard .past-results {
    text-align: center;
    font-size: 18px;
    padding: 10px;
}
#test-dashboard .past-results .styled-select {
    max-width: 220px;
    margin: 20px auto;
}

#test-dashboard .past-results h4 {
    font-size: 20px;
}
#test-dashboard .past-results p {
    text-align: center;
    margin-bottom: 5px;
    cursor: pointer;
}
#test-dashboard .past-results p:hover {
    text-decoration: underline;
}
#test-dashboard .session-info {
    display: inline-block;
    width: 31%;
    margin: 20px 1%;
}
#test-dashboard .session-info p {
    font-size: 14px;
    margin-bottom: 12px;
}
#test-dashboard .session-chart {
    display: inline-block;
    width: 64%;
    margin: 20px 1%;
    border: 1px solid #c7c7c7;
    vertical-align: top;
}
#test-dashboard .session-info a {
    display: block;
    font-size: 14px;
    margin-bottom: 15px;
}
#test-dashboard .session-info button {
    color: #0e5384;
    padding: 5px 10px;
    background-color: #fff;
    border: solid 1px #0e5384;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
}
#test-dashboard .session-info button svg {
    vertical-align: sub;
}
#test-dashboard .session-info p.score {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 12px;
}

#test-dashboard #sidebarRight a:hover {
    text-decoration: underline;
}
#test-dashboard #sidebarRight a:last-of-type {
    color: #0e5384;
    display: block;
    margin-top: 2px;
}
#test-dashboard .result-links .start-btn {
    display: block;
    max-width: 100%;
}
#test-dashboard .result-links .start-btn:hover {
    opacity: 0.8;
}
#test-dashboard .result-links button, #test-dashboard .result-links a.buyNow {
    border: none;
    background: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}
#test-dashboard .result-links a.buyNow {
    display: inline-block;
    padding: 0;
}
#test-dashboard .result-links a.buyNow:hover {
    border: none;
}
#test-dashboard .result-links .col50:first-child {
    width: 230px;
}
#test-dashboard .result-links .styled-select {
    display: block;
    margin-bottom: 10px;
    max-width: 230px;
}
#test-dashboard .result-links .cogat > div {
    width: 270px;
}
#test-dashboard .result-links .cogat .styled-select {
    max-width: 270px;
}

#test-dashboard .result-links > div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
#test-dashboard .result-links > div > div {
    margin: 0 10px;
}
#test-dashboard .result-links .styled-select select {
    height: 39px;
    font-size: 20px;
    color: #0e5384;
    padding-left: 5px;
    font-weight: bold;
}
#test-dashboard .result-links .styled-select:after {
    top: 18px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #0e5384;
}
#test-dashboard .result-links .col50:first-of-type {
    text-align: right;
}
#test-dashboard .result-links .col50:nth-of-type(2) {
    text-align: left;
}
.skill-specific .date {
    margin-top: 10px;
}
.skill-specific .score {
    margin-top: 20px;
}
.skill-specific .flex {
    display: flex;
    flex-wrap: wrap;
}
.skill-specific .info {
    flex-basis: 20%;
}
.skill-specific .chart-box {
    flex-basis: 39%;
}
#test-dashboard .prep-section {
    position: relative;
    margin: 0 30px;
    border: 1px solid #d9d9d9;
    padding: 0 5px;
}
#test-dashboard #interactive-practice {
    padding: 0;
}
#test-dashboard .prep-section .prep-content {
    padding: 10px 0 0;
}
#test-dashboard .prep-section .prep-content a.right {
    position: absolute;
    top: 40px;
    right: 30px;
}
#test-dashboard .prep-controls {
    display: flex;
    justify-content: left;
    padding: 15px 30px 0;
}
#test-dashboard .prep-controls .control {
    background-color: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-right: none;
    color: #0e5384;
    width: 20%;
    cursor: pointer;
    padding: 10px 3px;
    font-size: 18px;
    text-align: center;
}
#test-dashboard .prep-controls .control:focus {
    outline: none;
}
#test-dashboard .prep-controls .control.active {
    background-color: #c8eaff;
    border-bottom: 3px solid #0e5384;
    text-shadow: none;
}
#test-dashboard .prep-controls .control:first-of-type {
    margin-left: 0;
}
#test-dashboard .prep-controls .control:last-of-type {
    margin-right: 0;
    border-right: 1px solid #d9d9d9;
}
#test-dashboard .prep-controls .control svg {
    width: 100%;
}
#test-dashboard .prep-section .prep-content.open {
    display: block;
}
#test-dashboard .prep-section .prep-heading {
    position: relative;
    padding: 10px 0 20px;
}
#test-dashboard .prep-section .prep-heading p {
    text-align: center;
}
#test-dashboard .prep-section .prep-heading svg:first-child {
    margin-right: 10px;
}

#test-dashboard .prep-section .prep-heading svg:last-of-type {
    position: absolute;
    top: -30px;
    right: 30px;
    font-size: 30px;
    color: #fff;
}
#test-dashboard .prep-section .prep-heading.open svg:last-of-type {
    transform: rotate(180deg);
}
#test-dashboard .resource-table {
    width: 100%;
}
#test-dashboard .resource-table h4 {
    margin: 0 0 10px;
    padding: 0;
    border-bottom: none;
    color: #383b41;
    font-size: 18px;
    line-height: 1.33;
    text-align: left;
}
#test-dashboard .resource-table span.table-heading {
    color: #383b41;
    border-bottom: 2px solid #383b41;
    width: 15.5%;
    padding: 0 2.67% 5px;
}
#test-dashboard h4 {
    font-size: 16px;
    margin: 5px 1%;
}
#test-dashboard .resource-table table {
    border: none;
    margin: 20px 0;
    width: 100%;
    text-align: center;
    border-spacing: 0;
    position: relative;
    min-height: 250px;
    font-size: 16px;
}
#test-dashboard .resource-table .loading {
    top: 10px;
}
#test-dashboard .resource-table th {
    background-color: #fff;
    color: #000;
    border-bottom: 1px solid #bfbfbf;
    font-size: 18px;
    line-height: 1.91;
    font-weight: normal;
}
#test-dashboard .resource-table tr td {
    padding: 10px 0;
    border: none;
}
#test-dashboard .resource-table tr td:first-child {
    width: 48%;
    text-align: left;
    padding: 10px;
    color: #0e5384;
}
#test-dashboard .resource-table tr td:nth-child(2) {
    padding: 5px 0;
}
#test-dashboard .resource-table tr td:nth-child(2), #test-dashboard .resource-table tr td:nth-child(3),
#test-dashboard .resource-table tr td:nth-child(4) {
    width: 16%;
    color: #8a8a8a;
}
#test-dashboard .resource-table svg {
    vertical-align: sub;
    margin-right: 3px;
}
#test-dashboard .resource-table tr:nth-child(odd) {
    background: #fff;
}
#test-dashboard .resource-table tr:nth-child(even) {
    background: #f5fbff;
}
#test-dashboard .sba-game-container {
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #0e5384;
    background-color: #ffffff;
    padding: 6px;
    display: inline-block;
    width: 48%;
    position: relative;
    margin-bottom: 15px;
    vertical-align: top;
}
#test-dashboard .sba-game-container:nth-child(2n) {
    margin-left: 4%;
}
#test-dashboard .sba-game-container img {
    width: 100px;
    height: auto;
    float: left;
    margin-right: 10px;
}
#test-dashboard .sba-game-container .title {
    display: block;
    vertical-align: top;
    font-weight: bold;
    line-height: 1.36;
    text-align: left;
    color: #000000;
    margin-bottom: 8px;
    padding-right: 140px;
}
#test-dashboard .sba-game-container .description {
    font-size: 14px;
    line-height: 1.42;
    color: #000000;
    margin-bottom: 5px;
}
#test-dashboard .sba-game-container a {
    background-color: #ffffff;
    border-radius: 18px;
    padding: 3px 20px;
    display: inline-block;
    margin-left: -2px;
    font-weight: 800;
}
#test-dashboard .sba .sba-game-container a {
    border: solid 2px #17b7a9;
    color: #176d65;
}
#test-dashboard .games .sba-game-container a {
    border: solid 2px #01375d;
    color: #01375d;
}
#test-dashboard .sba-game-container .grades {
    position: absolute;
    top: 0;
    right: -1px;
    background-color: #0e5384;
    color: #fff;
    font-weight: 800;
    font-size: 12px;
    padding: 5px 8px;
    border-radius: 0 5px 0 5px;
}

#test-dashboard .prep-section h3 svg, #test-dashboard .prep-section h3 path {
    pointer-events: none;
}

#test-dashboard .back-top {
    text-align: center;
    color: #0e5384;
    margin-top: 60px;
    cursor: pointer;
}
#test-dashboard .back-top .caret-up {
    margin-left: 5px;
}
.assessment.results {
    display: none;
}
.results .arrow {
    position: absolute;
    z-index: 9999;
    top: 45%;
    font-size: 30px;
    left: -22px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 30px;
    background: #000;
    padding: 2px 8px 0;
    cursor: pointer;
}
.results .arrow.right {
    left: auto;
    right: -22px;
}
.no-digital-tutor {
    font-size: 18px;
    text-align: center;
    margin: 40px 0;
}
#test-dashboard .chart-title {
    text-align: center;
    margin-bottom: 20px;
}
#test-dashboard .btns .buy-now {
    float: right;
}
#test-dashboard .grade-select {
    max-width: 400px;
    width: 100%;
    margin-bottom: 20px;
}
.distractor {
    margin: 30px 0;
}
#test-dashboard .dashboard-monsters {
    width: 100%;
    max-width: 550px;
    margin: 0 auto -6.5px;
}
#test-dashboard .dashboard-monsters img {
    width: 25%;
    margin: 20px 4% 0;
}
#test-dashboard .heading-img {
    height: 40px;
    width: 40px;
    margin: 0 auto 5px;
    display: block;
}
#test-dashboard .italic-subtitle {
    font-style: italic;
    margin-bottom: 15px;
    text-align: center;
}
#test-dashboard .star-key:first-of-type {
    margin-left: 0;
}
#test-dashboard .key {
    margin: 0 0 20px 5px;
    display: block;
    width: 100%;
}
#test-dashboard .state-link {
    position: absolute;
    top: 4px;
    right: 10px;
    font-size: 15px;
}
#test-dashboard #printables {
    width: auto;
}
#test-dashboard .printables .closed h3 .caret {
    transform: rotate(0deg);
}
#test-dashboard .printables h3 .caret {
    color: #000;
}
#test-dashboard .practice-btn {
    display: inline-block;
    margin-top: 18px;
}
#test-dashboard .prep-section .practice-btn {
    display: block;
    width: 180px;
    margin: 20px auto 0;
}
#assessment-results .difficulty {
    margin-top: 10px;
}
#assessment-results .lrn .row {
    margin-left: 0;
    margin-right: 0;
}
#test-dashboard .thumbnail-link {
    display: block;
}
#test-dashboard .babies {
    width: 96%;
    max-width: 340px;
    text-align: center;
    margin: 30px auto 0;
}
#test-dashboard .babies img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
}
#test-dashboard .prep-controls .control:hover {
    background-color: #e5f3fc;
}
#test-dashboard .noInteractiveP {
    text-align: center;
    margin: 20px 0 150px;
}
#test-dashboard .noInteractiveP a {
    font-weight: 500;
}

#questions-results .lrn_widget .correct-answer, #questions-results .lrn_widget .wrong-answer {
    padding: 10px;
    background: #fcfcd3;
    width: 130px;
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
    display: inline-block;
}
#questions-results .lrn_widget .wrong-answer {
    background: #EBCCD1;
    margin-left: 20px;
}
.loading-container {
    height: 300px;
    width: 100%;
    position: relative;
}
@media (max-width: 1400px) {
    #test-dashboard .result-links {
        width: 98%;
        float: none;
    }
    #test-dashboard .past-results {
        width: 98%;
        float: none;
    }
    .skill-specific .info {
        flex-basis: 100%;
        text-align: center;
    }
    .skill-specific .chart-box {
        margin-top: 25px;
        flex-basis: 49%;
    }
}

@media (max-width: 1050px) {
    #test-dashboard .skill-specific h4 {
        display: block;
    }
    #test-dashboard .skill-specific .chart-box {
        flex-basis: 100%;
    }
    .skill-specific .date {
        margin-top: 0;
        margin-right: 0;
    }
    .skill-specific .score {
        margin-top: 0;
        margin-top: 20px;
    }
    .skill-specific .info h3, .skill-specific .info .date {
        display: inline-block;
    }
    #test-dashboard .recent-assessment span:first-child, #test-dashboard .recent-assessment, #test-dashboard .test-date {
        margin-bottom: 10px;
    }
    #test-dashboard .results table tr th:first-child, #test-dashboard .results table tr td:first-child {
        padding-left: 0;
        word-break: break-word;
    }
    #test-dashboard .results table tr th:nth-child(3n), #test-dashboard .results table tr td:nth-child(3n) {
        width: 20%;
    }
    #test-dashboard .results table th, #test-dashboard .results table td {
        text-align: center;
        font-size: 14px;
    }
    #test-dashboard .results table button {
        float: none;
        display: block;
        margin: 3px auto 0;
    }
    #test-dashboard .prep-section .prep-heading svg:first-child {
        margin-right: 0;
    }
    #test-dashboard .result-links {
        padding: 0 10px;
        margin: 0 auto;
    }
}

@media (max-width: 980px) {
    #test-dashboard .test-header p {
        margin: 35px 5px 0;
    }
    #test-dashboard .resource-table h4 {
        font-size: 16px;
    }
    #test-dashboard .resource-table th {
        font-size: 15px;
    }
    #test-dashboard .results-head {
        padding: 10px 10px 10px 5px;
    }
    #test-dashboard .results-head > div:nth-child(4), #test-dashboard .results-row > div:nth-child(4) {
        width: 28%;
    }
    #test-dashboard .result-links .col50 {
        width: 98%;
    }
    #test-dashboard .result-links .col50:first-of-type {
        text-align: center;
    }
    #test-dashboard .result-links button, #test-dashboard .result-links a.buyNow {
        display: inline-block;
        margin-bottom: 10px;
    }
    #test-dashboard .result-links a, #test-dashboard .result-links .styled-select {
        display: block;
        width: 100%;
        max-width: 500px;
        margin: 0 auto 10px;
    }
    #test-dashboard .prep-section .prep-heading h3 {
        padding-right: 0;
    }

    #test-dashboard .prep-section .prep-heading svg:last-of-type {
        right: 0;
    }
    #test-dashboard .about h2 {
        margin-top: 10px;
    }
}

@media (max-width: 800px) {
    #test-dashboard .results, #test-dashboard .results-row button {
        font-size: 14px;
    }
    #test-dashboard .results-row button svg {
        font-size: 12px;
    }
    #test-dashboard .prep-section .prep-content a.right {
        position: relative;
        top: -25px;
        float: none;
        left: auto;
        right: auto;
        display: block;
        text-align: center;
    }
    #test-dashboard .result-links .styled-select select, #test-dashboard .result-links a {
        font-size: 16px;
    }
    #test-dashboard .result-links .styled-select {
        padding: 2px 0 1px 5px;
    }
}
@media (max-width: 640px) {

    #test-dashboard .star-key {
        display: block;
        margin: 10px 0;
    }
    #test-dashboard .result-links > div {
        flex-wrap: wrap;
    }
    #test-dashboard .result-links > div > div {
        width: 230px;
        padding: 0 10px;
        box-sizing: content-box;
        margin: 0;
    }
    #test-dashboard .prep-controls {
        display: block;
    }
    #test-dashboard .prep-controls .control {
        width: 100%;
        border-bottom: none;
        margin: 0;
        display: block;
        border-right: 1px solid #d9d9d9;
    }
    #test-dashboard .prep-controls .control svg {
        margin-right: 5px;
        width: auto;
    }
    #test-dashboard .start-practice .links-right {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column-reverse;
    }
}
@media (max-width: 560px) {
    #test-dashboard .dashboard .section {
        padding: 25px 15px;
    }
    .skill-specific h3, .skill-specific .score {
        font-size: 18px;
    }
    #test-dashboard .results table {
        width: 160%;
    }
    #test-dashboard .resource-table table {
        width: 125%;
    }
    #test-dashboard .results-head > div:nth-child(1), #test-dashboard .results-row > div:nth-child(1) {
        width: calc(100% - 100px);
    }
    #test-dashboard .results-head > div:nth-child(2), #test-dashboard .results-row > div:nth-child(2) {
        display: none;
    }
    #test-dashboard .results-head > div:nth-child(3), #test-dashboard .results-row > div:nth-child(3) {
        width: 100px;
    }
    #test-dashboard .thumbnail-link .star-bg {
        left: auto;
        right: 0;
    }
    #test-dashboard .key {
        width: 100%;
    }
}

@media (max-width: 480px) {
    #test-dashboard .prep-section h3 {
        text-align: center;
    }
    #test-dashboard .sba .sba-game-container {
        padding-bottom: 30px;
    }
    #test-dashboard .sba-game-container .title {
        text-align: center;
        padding: 0;
    }
    #test-dashboard .sba-game-container img {
        width: 160px;
        display: block;
        height: auto;
        float: none;
        margin: 0 auto 10px;
        max-width: 160px;
    }
    #test-dashboard .sba-game-container .title, #test-dashboard .sba-game-container .description {
        text-align: center;
    }
    #test-dashboard .sba-game-container .grades {
        width: 100%;
        top: auto;
        bottom: 0;
        left: 0;
        border-radius: 0 0 5px 5px;
        text-align: center;
    }
    #test-dashboard .result-links a {
        width: 100%;
    }
    #test-dashboard .prep-section h3 {
        padding-right: 10px;
    }
    #test-dashboard .prep-section h3 svg:last-of-type {
        right: -8px;
        top: 0;
    }
}
