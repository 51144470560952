.collapsible-header {
    position: relative;
    padding: 10px 0 10px 30px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: #eaeaea;
}
.lesson .collapsible-header  h3 {
    border-bottom: none;
    display: inline-block;
    color: #0e5384;
    margin: 0;
}
.lesson .collapsible-header span {
    position: absolute;
    border-radius: 3px;
    right: 15px;
    top: 2px;
    display: block;
    height: 30px;
    width: 30px;
    font-size: 32px;
    transition: transform 0.5s;
}
.lesson .collapsible-content {
    box-sizing: border-box;
    display: none;
    transition: display 0.5s;
}
.lesson.open .collapsible-content {
    padding: 0 0 20px;
    display: block;
    overflow: auto;
}
.lesson .collapsible-header svg {
    transition: transform 0.5s;
    margin: 2px auto;
    display: block;
}
.lesson.open .collapsible-header svg {
    transform: rotate(180deg);
}
.lesson-content {
    width: 96%;
    padding: 10px 20px;
}
.lesson .collapsible-content img {
    max-width: 100%;
}
.lesson .videos .col50 {
    margin-bottom: 35px;
}
.lesson .videos p {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #0d5282;
}
.lesson.open .collapsible-content.videos {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
}
.lesson .videos > div {
    flex-basis: 46%;
    margin: 0 2% 35px;
}

@media (max-width: 820px) {
    .lesson .videos > div {
        flex-basis: 96%;
        margin: 0 2% 35px;
    }
}

@media (max-width: 560px) {
    .collapsible-header {
        padding: 10px 40px 10px 10px;
    }
    .lesson .collapsible-header span {
        right: 10px;
    }
}
